.learn-card__container {
  margin-top: 16px;
}
.learn-card__hover:hover {
  background: #DCECF6;
  cursor: pointer;
}

.learn-card__span-text {
  font-size: 16px;
}
