.spin-earth-on-hover {
    transition: ease 200s !important;
    transform: rotate(-3600deg) !important;
}

#not-found {
    position: absolute;
    margin: 0;
    width: 100%;
    height: 100%;
    font-weight: 300;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
}

#not-found .bg-purple {
    background-size: cover;
    background: url(http://salehriaz.com/404Page/img/bg_purple.png) repeat-x left top;
    height: 100%;
    overflow: hidden;
}

#not-found .custom-navbar {
    padding-top: 15px;
}

#not-found .brand-logo {
    margin-left: 25px;
    margin-top: 5px;
    display: inline-block;
}

#not-found .navbar-links {
    display: inline-block;
    float: right;
    margin-right: 15px;
    text-transform: uppercase;
}

#not-found ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

#not-found li {
    float: left;
    padding: 0 15px;
}

#not-found li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 12px;

    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

#not-found li a:hover {
    color: #ffcb39;
}

#not-found .btn-request {
    padding: 10px 25px;
    border: 1px solid #ffcb39;
    border-radius: 100px;
    font-weight: 400;
}

#not-found .btn-request:hover {
    background-color: #ffcb39;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
}

#not-found .btn-go-home {
    position: relative;
    z-index: 200;
    margin: 15px auto;
    width: 100px;
    padding: 10px 15px;
    border: 1px solid #ffcb39;
    border-radius: 100px;
    font-weight: 400;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 11px;

    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

#not-found .btn-go-home:hover {
    background-color: #ffcb39;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
}

#not-found .central-body {
    display: flex;
    gap: 32px;
    align-items: center;
}

.central-body__text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.central-body-text__span {
    padding: 16px;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
}

.central-body-text__btn {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    height: 50px;
}

#not-found .objects img {
    z-index: 90;
    pointer-events: none;
}

#not-found .object_rocket {
    z-index: 95;
    position: absolute;
    transform: translateX(-50px);
    top: 75%;
    pointer-events: none;
    animation: rocket-movement 200s linear infinite both running;
}

#not-found .object_earth {
    position: absolute;
    top: 20%;
    left: 15%;
    z-index: 90;
}

#not-found .object_moon {
    position: absolute;
    top: 12%;
    left: 25%;
}

#not-found .object_astronaut {
    animation: rotate-astronaut 200s infinite linear both alternate;
}

#not-found .box_astronaut {
    z-index: 110 !important;
    position: absolute;
    top: 60%;
    right: 20%;
    will-change: transform;
    animation: move-astronaut 50s infinite linear both alternate;
}

#not-found .image-404 {
    position: relative;
    z-index: 100;
    pointer-events: none;
}

#not-found .stars {
    background-size: contain;
    background: url(http://salehriaz.com/404Page/img/overlay_stars.svg) repeat left top;
}

#not-found .glowing_stars .star {
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    width: 3px;
    height: 3px;
    opacity: 0.3;
    will-change: opacity;
}

#not-found .glowing_stars .star:nth-child(1) {
    top: 80%;
    left: 25%;
    animation: glow-star 2s infinite ease-in-out alternate 1s;
}

#not-found .glowing_stars .star:nth-child(2) {
    top: 20%;
    left: 40%;
    animation: glow-star 2s infinite ease-in-out alternate 3s;
}

#not-found .glowing_stars .star:nth-child(3) {
    top: 25%;
    left: 25%;
    animation: glow-star 2s infinite ease-in-out alternate 5s;
}

#not-found .glowing_stars .star:nth-child(4) {
    top: 75%;
    left: 80%;
    animation: glow-star 2s infinite ease-in-out alternate 7s;
}

#not-found .glowing_stars .star:nth-child(5) {
    top: 90%;
    left: 50%;
    animation: glow-star 2s infinite ease-in-out alternate 9s;
}

@media only screen and (max-width: 600px) {
    #not-found .navbar-links {
        display: none;
    }

    #not-found .custom-navbar {
        text-align: center;
    }

    #not-found .brand-logo img {
        width: 120px;
    }

    #not-found .box_astronaut {
        top: 70%;
    }

    .central-body__text {
        margin-bottom: 10px;
    }

    .central-body-text__span {
        font-size: 16px;
    }

}

@media only screen and (max-width: 1280px) {

    .central-body__text {
        margin-right: 10%;
    }

    .central-body-text__span {
        font-size: 24px;
        line-height: 32px;
        width: 45vh;
    }

    .central-body-text__btn {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        height: 46px;
    }
}

@media only screen and (max-width: 1000px) {

    #not-found .central-body {
        flex-direction: column;
        gap: 16px;
    }

    .central-body__text {
        margin-right: 0;
        margin-bottom: 60px;
    }

    .central-body-text__span {
        font-size: 18px;
    }

    .central-body-text__btn {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        height: 46px;
    }
}

@-moz-keyframes rocket-movement {
    100% {
        -moz-transform: translate(1200px, -600px);
    }
}

@-webkit-keyframes rocket-movement {
    100% {
        -webkit-transform: translate(1200px, -600px);
    }
}

@keyframes rocket-movement {
    100% {
        transform: translate(1200px, -600px);
    }
}

@-moz-keyframes spin-earth {
    100% {
        -moz-transform: rotate(-360deg);
        transition: transform 20s;
    }
}

@-webkit-keyframes spin-earth {
    100% {
        -webkit-transform: rotate(-360deg);
        transition: transform 20s;
    }
}

@keyframes spin-earth {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        transition: transform 20s;
    }
}

@-moz-keyframes move-astronaut {
    100% {
        -moz-transform: translate(-160px, -160px);
    }
}

@-webkit-keyframes move-astronaut {
    100% {
        -webkit-transform: translate(-160px, -160px);
    }
}

@keyframes move-astronaut {
    100% {
        -webkit-transform: translate(-160px, -160px);
        transform: translate(-160px, -160px);
    }
}

@-moz-keyframes rotate-astronaut {
    100% {
        -moz-transform: rotate(-720deg);
    }
}

@-webkit-keyframes rotate-astronaut {
    100% {
        -webkit-transform: rotate(-720deg);
    }
}

@keyframes rotate-astronaut {
    100% {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}

@-moz-keyframes glow-star {
    40% {
        -moz-opacity: 0.3;
    }
    90%,
    100% {
        -moz-opacity: 1;
        -moz-transform: scale(1.2);
    }
}

@-webkit-keyframes glow-star {
    40% {
        -webkit-opacity: 0.3;
    }
    90%,
    100% {
        -webkit-opacity: 1;
        -webkit-transform: scale(1.2);
    }
}

@keyframes glow-star {
    40% {
        -webkit-opacity: 0.3;
        opacity: 0.3;
    }
    90%,
    100% {
        -webkit-opacity: 1;
        opacity: 1;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        border-radius: 999999px;
    }
}
