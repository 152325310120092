#word-card .animation {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#word-card .word-card__content__card {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    -o-perspective: 800px;
    perspective: 800px;
    padding-left: 1%;
    text-align: center;
}

#word-card .word-card__content__card > .word-card__arrows {
    display: flex;
    margin: 0 auto;
    width: 100px;
    justify-content: space-between;
}

.word-card__btn-area {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 16px;
}

#word-card .word-card__content__card > svg {
    margin-top: 20px;
    margin-bottom: -60px;
    margin-left: 450px;
}

#word-card .card {
    margin: 30px auto;
    width: 450px;
    height: 200px;
    cursor: pointer;

    /*transition effects */
    -webkit-transition: -webkit-transform 0.6s;
    -moz-transition: -moz-transform 0.6s;
    -o-transition: -o-transform 0.6s;
    transition: transform 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

#word-card .flipped {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

#word-card .front,
#word-card .back {
    display: grid;
    grid-template-columns: 1fr 70px;
    border-radius: 10px;
    cursor: alias;
    height: 100%;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 2em;
    position: absolute;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 3px 5px 20px 2px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
}

#word-card .front:hover {
    animation: card-animation 1s linear;
    transform: translate3d(0, 0, 0);
}

#word-card .card__card-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#word-card .card__speech {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#word-card .back {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
}

#word-card .back {
    background: #03446a;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

/*Colors for front and back applied here*/
#word-card .word-card__content__card:first-child .front {
    background: #4582FF;
    display: flex;
    justify-content: center;
}

#word-card .word-card__content__card:first-child .back {
    background: #4582FF;
    display: flex;
    justify-content: center;
}

#word-card .fa-volume-up {
    cursor: pointer;
}

#word-card .word-card__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 100px;
    margin: 10px auto;
    width: 50%;
}

#word-card .word-card__header #counter {
    justify-self: end;
    margin-top: 10px;
    font-size: 30px;
}

#word-card .word-card__header .bright-counter {
    color: green;
}

.word-card-btn-area {
    display: flex;
    flex-direction: column;
   align-items: center;
}

.word-card-btn-area__remember-btn {
    display: flex;
    justify-content: center;
}

.wordlist-combine-selector .checkbox__text {
    margin: 30px;
}

#word-card .wordButton:last-of-type {
    background-color: #e03366;
}

#word-card #currentEngWord:first-letter,
#word-card #currentRusWord:first-letter {
    text-transform: uppercase;
}

#word-card .front .fa:hover {
    color: black;
}

#word-card .randomLabel {
    cursor: pointer;
    border-radius: 10px;
    color: white;
    margin: 10px auto;
    padding: 5px;
    font-size: 1em;
    text-align: center;
    display: block;
    width: 200px;
    background-color: #2aa9e0;
    box-shadow: 0 2px 5px rgb(0, 0, 0);
}

#word-card .randomizer input:checked ~ .randomLabel {
    background-color: #e03366;
}

#word-card .randomLabel:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
}

#word-card .randomizer input {
    display: none;
}

#word-card .btn-sound {
    cursor: pointer;
}

@keyframes card-animation {
    10% {
        transform: rotateY(2deg);
    }
    80% {
        transform: rotateY(10deg);
    }
    90% {
        transform: rotateY(-4deg);
    }
}

@media all and (max-width: 600px) {
    #word-card .word-card__content__card:nth-child(3),
    #word-card .word-card__content__card:nth-child(4) {
        margin-top: 3%;
    }

    #word-card .card {
        width: 300px;
        font-size: 10px;
    }

    #word-card .word-card__content__card > svg {
        margin-left: 300px;
    }

    #word-card .word-card__header {
        width: 100%;
    }
    .word-card__btn-area {
        flex-direction: column;
    }

    .word-card-btn-area__remember-btn {
        flex-direction: column;
    }
}
