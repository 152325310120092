#main-dialog {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    z-index: 9999;
}

#main-dialog-700px {
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    z-index: 9999;
}

#main-dialog_message {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#main-dialog_message span {
    font-size: 1.2em;
    padding: 10px;
}

#main-dialog_bg_layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100vh;
    background: #000;
    opacity: 0.5;
    overflow: hidden;
}

#main-dialog .main-dialog__button-field {
    display: flex;
    justify-content: space-around;
}
