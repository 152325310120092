.main_footer {
    background-color: #252838;
    font-size: 1.2em;
    color: white;
    text-align: center;
    padding: 16px;
}

.main_footer a {
    font-size: 1em;
    color: white;
    padding: 0 10px;
}
