#guess-word .guess-word-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#guess-word .guess-word-area > .guess-word-area__words {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    font-size: 50px;
    align-items: center;
}

#guess-word .guess-word-area > .guess-word-area__rus {
    font-size: 30px;
    margin: 10px;
}

#guess-word .guess-word-area__buttons > .guess-word-area__help {
    margin: 30px auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#guess-word .guess-word-area__help > span {
    margin-left: 10px;
}

#guess-word .guess-word-area__help > svg {
    cursor: pointer;
}

#guess-word .guess-word-area__buttons > .guess-word-area__btns > input {
    text-align: center;
    cursor: pointer;
    display: block;
    width: 300px;
    background-color: #2aa9e0;
    font-size: 2em;
    border: none;
    border-radius: 10px;
    color: white;
    -webkit-border-before: none;
    box-shadow: 0 2px 5px rgb(0 0 0);
    -webkit-appearance: none;
    margin: 30px auto;
}

#guess-word .guess-word-area__btns {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

#guess-word h1 {
    text-align: center;
}

#guess-word .fill-word__content_grid {
    display: grid;
    justify-content: center;
}

#guess-word .guess-word-area__words > .guess-word__word {
    margin: 3px 7px;
    display: flex;
    flex-direction: row;
}

#guess-word .guess-word__letter > input {
    height: 80px;
    width: 70px;
    font-size: 50px;
    text-align: center;
    border-radius: 5px;
    margin: 0 2px;
    border: black 2px solid;
}

#guess-word .guess-word__letter-guessed input {
    border: green 2px solid;
    background-color: #b6f1c6;
}

#guess-word .guess-word__letter-failed input {
    border: red 2px solid;
    background-color: #f3abab;
}

@media screen and (min-width: 1680px) {
    #guess-word .fill-word__content_grid {
        grid-template-columns: 1fr 350px;
    }

    #guess-word #wordlist-combine-selector {
        margin-right: 30px;
        grid-column: 2;
        height: 300px;
        grid-row: 1;
    }

    #guess-word .guess-word-area {
        grid-column: 1;
        grid-row: 1;
    }
}

@media screen and (max-width: 640px) {
    #guess-word .guess-word__letter > input {
        height: 40px;
        width: 40px;
        font-size: 20px;
    }

    #guess-word .guess-word-area > .guess-word-area__rus {
        font-size: 20px;
        margin: 10px;
    }
}
