.profile_info img {
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
    margin-left: 10px;
}

.profile_info span {
    font-size: 20px;
    list-style-type: none;
}

.profile_info input {
    background-color: #e9e9e9;
    border: none;
    font-size: 15px;
    color: black;
    grid-column: 2/4;
    width: 60%;
    border-radius: 10px;
    height: 80%;
}

.profile_info .data {
    color: #000000;
    grid-column: 2/4;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
}
.profile_info .edit_btn {
    height: 20px;
    width: 20px;
    margin-left: 10px;
}
.none {
    display: none;
}
