$backgroundColorButton: #2aa9e0;
$colorError: #ff0000ff;
$media-font-size: 17px;
$form-margin-top: 10px;

.change-password__form {
  margin: 200px auto;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.form__input-new-password {
  font-size: 2em;
  text-align: center;
  margin-top: $form-margin-top;
}


.change-password__error-message {
  color: $colorError;
}

@media (max-width: 600px) {
  .form__input-new-password {
    font-size: $media-font-size;
  }
  .change-password__form {
    width: 80%;
  }
}
