@import '../variables.scss';

.courses__details {
  @include card-container;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;

  .courses-details__title {
    font-size: 24px;
    font-weight: 300;
  }

  .courses-details__text {
    font-size: 24px;
    margin-top: 20%;
    margin-bottom: 20%;
  }
}

@media screen and (max-width: 1050px) {
  .courses__details {
    width: 23%;

    .courses-details__title {
      font-size: 18px;
      font-weight: 300;
    }
  }
}

@media screen and (max-width: 935px) {
  .courses__details {
    width: 22%;

    .courses-details__title {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

@media screen and (max-width: 770px) {
  .courses__details {
    width: 35%;
    .courses-details__title {
      font-size: 14px;
      font-weight: 300;
    }
    .courses-details__text {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 600px) {
  .courses__details {
    width: 47%;
  }
}
