.icon-btn {
    height: 20px;
    width: 20px;
    cursor: pointer;
    transition: 150ms ease;
    --light-blue: #00bcd4;
    --white: #e9e9e9;
    --green: #4caf50;
    --red: #ef1a1a;
}

.icon-btn.stroke-white:hover {
    stroke: var(--white);
}

.icon-btn.stroke-light-blue:hover {
    stroke: var(--light-blue);
}

.icon-btn.fill-white:hover path {
    fill: var(--white);
}

.icon-btn.fill-light-blue:hover path {
    fill: var(--light-blue);
}

.icon-btn.fill-green:hover circle {
    fill: var(--green);
}

.icon-btn.fill-red:hover path {
    fill: var(--red);
}

.button__icon-margin {
    position: absolute;
    left: 10px;
}
