#simple-selector {
    margin: 20px 10px;
    position: relative;
    width: 250px;
    cursor: pointer;
}

.simple-selector__select-text {
    cursor: pointer;
    position: relative;
    font-family: inherit;
    background-color: transparent;
    width: 250px;
    padding: 10px 10px 10px 0;
    font-size: 18px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* Remove focus */
.simple-selector__select-text:focus {
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

/* Use custom arrow */
#simple-selector .simple-selector__select-text {
    appearance: none;
    -webkit-appearance: none;
}

#simple-selector:after {
    position: absolute;
    top: 18px;
    right: 10px;
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.12);
    pointer-events: none;
}

.simple-selector__select-label {
    color: rgba(0, 0, 0, 0.26);
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 10px;
    transition: 0.2s ease all;
}

.simple-selector__select-text:focus ~ .simple-selector__select-label,
.simple-selector__select-text:valid ~ .simple-selector__select-label {
    color: #2f80ed;
    top: -20px;
    transition: 0.2s ease all;
    font-size: 14px;
}

.simple-selector__select-bar {
    position: relative;
    display: block;
    width: 250px;
}

.simple-selector__select-bar:before,
.simple-selector__select-bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #2f80ed;
    transition: 0.2s ease all;
}

.simple-selector__select-bar:before {
    left: 50%;
}

.simple-selector__select-bar:after {
    right: 50%;
}

/* active state */
.simple-selector__select-text:focus ~ .simple-selector__select-bar:before,
.simple-selector__select-text:focus ~ .simple-selector__select-bar:after {
    width: 50%;
}

.simple-selector__select-highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}
#simple-selector select:disabled {
    background: #ccc;
}
#simple-selector .disabled {
    display: none;
}
