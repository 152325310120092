.word-list__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
}

.word-list__container-card {
    display: flex;
}

.word-list-container__card{
    display: flex;
    flex-direction: column;
}

.word-list__flex{
    display: flex;
    flex-direction: column;
}

.word-list_eng {
    border-color: rgba(224, 51, 102, 0.8) ;
    border-width: 2px;
    text-align: center;
    font-size: 24px;
    min-width:374px;
    overflow: hidden;
}
.word-list_rus {
    border-color: rgba(22, 119, 255, 0.8);
    border-width: 2px;
    text-align: center;
    font-size: 24px;
    min-width:374px;
    overflow: hidden;
}
.word-item__input-word-name {
    padding: 2px;
    background-color: #e9e9e9;
    border: 1px solid black;
}

.word-item__input-word-name_error {
    padding: 2px;
    background-color: rgba(253, 175, 175, 0.72);
    border: 1px solid red;
}

.clue {
    display: flex;
    justify-content: center;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 10px;
    text-align: center;
    padding: 5px 0;
    font-size: 18px;
    cursor: pointer;
}
.clue:hover{
    border: 1px solid #028dff;
}

@media (max-width: 840px) {
    .word_item {
        display: flex;
        flex-direction: column;
    }

    .modal-word-card__button-area {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 430px) {
    .word-list_eng {
        min-width:354px;
    }
    .word-list_rus {
        min-width:354px;
    }
}

@media (max-width: 420px) {
    .word-list_eng {
        min-width:284px;
    }
    .word-list_rus {
        min-width:284px;
    }
}

@media (max-width: 400px) {
    .word-list_eng {
        min-width:314px;
    }
    .word-list_rus {
        min-width:314px;
    }
}

@media (max-width: 300px) {
    .word-list_eng {
        font-size: 18px;
        min-width:214px;
    }
    .word-list_rus {
        font-size: 18px;
        min-width:214px;
    }
}