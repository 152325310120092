
%item {
    width: 25px;
    height: 4px;
    background-color: white;
    border-radius: 5px;
}
.burger-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.burger-item {
   @extend %item;
    position: relative;
    &::after {
        content: '';
        @extend %item;
        position: absolute;
        top: 10px;
    }
    &::before {
        @extend %item;
        content: '';
        position: absolute;
        top: -10px;
    }
}
