#add-privilege-dialog {
    width: 700px;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    max-height: 70vh;
    min-height: 70vh;
}
#add-privilege-dialog#add-privilege-dialog_content {
    max-height: 80%;
    min-height: 80%;
    height: 65vh;
    overflow-y: auto;
    color: black;
}

#add-privilege-dialog#add-privilege-dialog_content li {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#add-privilege-dialogbutton {
    box-shadow: 0 2px 5px rgb(0, 0, 0);
    transition: transform 0.1s ease-in;
    background-color: red;
    border-radius: 10px;
    color: white;
    border: none;
    cursor: pointer;
    margin: 10px;
    padding: 5px;
}

#add-privilege-dialogbutton:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
    -webkit-transform: scale(1.025);
    transform: scale(1.015);
}

@media screen and (max-width: 1100px) {
    #add-privilege-dialog {
        width: 600px;
    }
}

@media screen and (max-width: 640px) {
    #add-privilege-dialog {
        width: 90%;
    }
}

@media screen and (max-width: 480px) {
    #add-privilege-dialog {
        width: 90%;
    }
}
