.profile_page {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 200px 200px 1fr 1fr;
    grid-template-areas: 'profile communities' 'profile friends' 'profile .' 'profile .';
    min-height: 50vh;
    width: 90%;
    margin: 60px auto;
}

.profile_page .profile_page_profile {
    grid-area: profile;
    position: relative;
    left: 0;
    top: 0;
    border-radius: 10px;
}

.profile_page .profile_page_friends {
    grid-area: friends;
    border-radius: 10px;
}

.profile_page .profile-page-friends_title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.profile_page .profile_page_profile,
.profile_page .profile_page_friends,
.profile_page .profile_page_chats,
.profile_page .profile_page_achieves {
    display: flex;
    flex-direction: column;
    background: #4583ff;
    text-decoration: none;
    color: #000000;
    box-shadow: 0 2px 5px rgb(0, 0, 0);
    transition: -webkit-transform 0.1s ease-in;
    transition: transform 0.1s ease-in;
    transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
}

.profile_page .profile_photo {
    margin: 0 auto;
    padding-top: 10px;
    cursor: pointer;
}

.profile_page .profile_photo > img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
}

.profile_page .premium-profile {
    border: 2px solid #ff9300;
}

.profile_page .profile_exit_button {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 50px;
    width: 50px;
    margin: 0;
    cursor: pointer;
}

.profile_page .profile_title {
    padding-top: 15px;
    padding-left: 15px;
    font-size: 2rem;
}

.profile_page .profile_info {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    padding: 0 20px;
}

.profile_page .profile_info > .btn-go-admin-page {
    color: red;
}

.profile_page .profile_info li,
.profile_page .profile_info li > span {
    font-size: 20px;
    list-style-type: none;
    padding: 5px 0;
}

.profile_page .profile_info > li {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    color: #c4c4c4;
}

.profile_page .profile_info > li .profile_info_title,
.profile_page .profile_info > li .profile_data {
    flex-basis: 30%;
    padding-right: 20px;
    align-self: center;
    font-size: 1.3rem;
}

.profile_page .profile_info > li.profile_info_title input {
    display: none;
}

.profile_page .profile_info > li.profile_info_title .checkbox__text {
    margin-top: -8px;
}

.profile_page .profile_info_edit_btn button {
    cursor: pointer;
    border: none;
    border-radius: 20px;
    background: -webkit-linear-gradient(
            45deg,
            #851988 50%,
            #4583ff
    ); /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
    background: -moz-linear-gradient(45deg, #851988 50%, #4583ff); /* Firefox 3.6-15 */
    background: -o-linear-gradient(45deg, #851988 50%, #4583ff); /* Opera 11.1-12 */
    background: linear-gradient(45deg, #851988 50%, #4583ff);
}

.profile_page .profile_info_edit_btn button:hover {
    background: rgba(0, 236, 0, 0.52);
}

.profile_page .profile_info_app_cancel button:nth-child(1) {
    cursor: pointer;
    border: none;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    background: rgba(0, 236, 0, 0.52);
}

.profile_page .profile_info_app_cancel button:nth-child(2) {
    cursor: pointer;
    border: none;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    background: rgba(236, 6, 21, 0.52);
}

.profile_page .profile_page_profile__status {
    font-size: 1.2em;
    color: #ff9300;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_page .profile_info_data {
    color: black;
}

.profile_page .profile_info_data_question {
    margin-left: 10px;
}

.profile_page .profile_info_data_question svg {
    position: relative;
    top: 5px;
}

.profile_page .profile_info_data-button {
    margin-left: 10px;
    border: none;
    cursor: pointer;
    padding: 2px 15px;
    box-shadow: 5px 5px 5px rgba(198, 198, 198, 0.16), -5px -5px 5px rgba(255, 255, 255, 0.3);
    background-color: #e9e9e9;
    border-radius: 10px;
}

.none {
    display: none;
}

.profile_page .profile_page-action-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.profile_page .profile_page-action-btns > button {
    margin: 10px;
}

.profile_page .profile_page-action-btns .buy-premium {
    background-color: #fcfc71;
    cursor: pointer;
    padding: 3px;
    border-radius: 5px;
    color: #000000;
}

.profile_page .profile_page-action-btns .buy-premium:hover {
    color: #1574f5;
}

.profile_info_title-sub-btn > span {
    font-size: 10px !important;
}

@media screen and (max-width: 1100px) {
    .profile_page {
        grid-template-rows: 250px 250px 1fr 1fr;
    }

    .profile_page .profile_info > li .profile_info_title,
    .profile_page .profile_info > li .profile_data {
        flex-basis: 45%;
        padding-right: 10px;
    }
}

@media screen and (max-width: 1386px) {
    .profile_page {
        grid-gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 200px 200px;
        grid-template-areas: 'profile' 'communities' 'friends';
        margin: 20px auto;
        width: calc(100% - 40px);
    }

    .profile_page .profile_info > li .profile_info_title,
    .profile_page .profile_info > li .profile_data {
        flex-basis: 40%;
    }
}

@media screen and (max-width: 640px) {
    .profile_page .profile_title {
        font-size: 1.5rem;
    }

    .profile_page .profile_info li,
    .profile_page .profile_info li > span,
    .profile_page .profile_info > li .profile_info_title,
    .profile_page .profile_info > li .profile_data {
        font-size: 1.2rem;
    }

    .profile_page .profile_page_profile,
    .profile_page .profile_page_friends,
    .profile_page .profile_page_chats,
    .profile_page .profile_page_achieves {
        width: calc(100vw - 40px);
    }
}

@media screen and (max-width: 480px) {
    .profile_page .profile_title {
        font-size: 1.3rem;
    }

    .profile_page .profile_info li,
    .profile_page .profile_info li > span,
    .profile_page .profile_info > li .profile_info_title,
    .profile_page .profile_info > li .profile_data {
        font-size: 1rem;
    }
}
