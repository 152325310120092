.custom_main_message {
    margin-top: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
}

.custom_main_message .select_category {
    color: black;
    font-size: 20px;
    text-align: center;
}

.custom_main_message span {
    margin-top: 20px;
    width: 60px;
    height: 15px;
    background: #888888;
    -moz-border-radius: 100px / 50px;
    -webkit-border-radius: 100px / 50px;
    border-radius: 100px / 50px;
    animation-duration: 1.5s;
    animation-name: oval;
    animation-iteration-count: infinite;
}

.custom_main_message img {
    position: relative;
    animation-duration: 1.5s;
    animation-name: slidein;
    animation-iteration-count: infinite;
}
@keyframes slidein {
    from {
        top: 0;
    }

    50% {
        top: 10px;
    }
    to {
        top: 0;
    }
}

@keyframes oval {
    from {
        top: 0;
    }

    50% {
        width: 70px;
    }
    to {
        top: 0;
    }
}
