#new-consomer .block-content {
    min-height: 100vh;
}

#new-consomer .block-content p {
    font-size: 1.1em;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

#new-consomer .new-comers-img {
    width: 300px;
}

#new-consomer .block-content h2 {
    text-align: center;
    margin: 1em 0 0 0;
    font-size: 2.5em;
    line-height: 1;
    letter-spacing: 0.02em;
}

#new-consomer .subtitle {
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2em;
    margin: 2em 0;
}

#new-consomer .heading-group {
    margin: 0 0 3em 0;
}

#new-consomer .content h3 {
    font-size: 1.2em;
    line-height: 1.2;
    letter-spacing: 0.02em;
}

@media all and (min-width: 40em) {
    #new-consomer .content h3 {
        font-size: 2.5em;
    }
}

#new-consomer h1,
#new-consomer h2,
#new-consomer h3 {
    font-weight: 900;
}

#new-consomer .l-block-spacing {
    min-height: 80vh;
    padding: 2em 0 4em 0;
}

#new-consomer .l-contained {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em;
}

@media all and (min-width: 40em) {
    #new-consomer .l-contained {
        padding-left: 2em;
        padding-right: 2em;
    }
}

#new-consomer .shake-btn {
    -webkit-animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
    -moz-animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
    -o-animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
    animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
    box-shadow: 0 2px 5px rgb(0, 0, 0);
    padding: 10px 40px;
    font-size: 30px;
    border: none;
    cursor: pointer;
    background-color: #3084FE;
    border-radius: 10px;
    color: white;
    margin-left: 8px;
}

#new-consomer .shake-btn:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
    -webkit-transform: scale(1.025);
    transform: scale(1.025);
}

@-webkit-keyframes revealFromLeft {
    0% {
        -webkit-transform: translateX(-1em);
        transform: translateX(-1em);
        opacity: 0;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes revealFromLeft {
    0% {
        -webkit-transform: translateX(-1em);
        transform: translateX(-1em);
        opacity: 0;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes reveal {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes reveal {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes scaleVertical {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

@keyframes scaleVertical {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

@-webkit-keyframes revealScaleUp {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes revealScaleUp {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes trambling-animation {
    0%,
    50%,
    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
    }
    10%,
    30% {
        transform: rotate(-2deg);
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
    }
    20%,
    40% {
        transform: rotate(2deg);
        -webkit-transform: rotate(2deg);
        -moz-transform: rotate(2deg);
        -o-transform: rotate(2deg);
        -ms-transform: rotate(2deg);
    }
}

@media screen and (max-width: 700px) {
    #new-consomer .section-2 .section-2__cards {
        grid-template-columns: repeat(1, 1fr);
    }
}
