#wordlist-combine-selector {
    margin-top: 30px;
    display: grid;
    place-items: center;
}

#wordlist-combine-selector .wordlist-combine-selector__help {
    margin-top: 10px;
    text-align: center;

}
