//button colors
$colorBtnBlue: #2aa9e0;
$colorBtnHoverBlue: #73c9f8;
$menuColor: #3C5482;
$colorYellow: #fcfc71;
//bgColors
$colorBgWhite: #ffffffff;
//status colors
$colorDone: #29bf14;
$colorError: #ff0000ff;
//dictionary colors
$colorDictionaryInput: #e9e9e9;
//mixins
@mixin center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin card-container {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: -10px 14px 14px 0 rgba(0, 0, 0, 0.15);
}

@mixin breakpoint($point) {
  @if $point == lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point == md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == sm {
    @media (max-width: 600px) {
      @content;
    }
  } @else if $point == xs {
    @media (max-width: 480px) {
      @content;
    }
  }
}
