#dialogs {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    height: calc(100vh - 63px);
}
@media screen and (max-width: 640px) {
    #dialogs {
        grid-template-columns: 1fr;
    }
}
