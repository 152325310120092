.modal-login {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
}

.modal-login__body {
    position: relative;
    border-radius: 4px;
    min-width: 480px;
    background: #fff;
    text-align: center;
    padding: 16px !important;
}

.modal-login__body label:hover {
    color: inherit;
}

.modal-login__title {
    font-size: 1.6rem;
    font-weight: 700;
}

.modal-login__btn-close {
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 0;
    border: none;
    background: transparent;
}

.login-registration-btn {
    margin-bottom: 10px;
}

.login-registration-btn-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.close-icon {
    width: 32px;
    height: 32px;
    color: rgba(0, 0, 0, 0.4);
}

.modal-login__link {
    display: block;
    margin-top: 24px;
    font-size: 14px;
}

.modal-login__form {
    margin-top: 48px;
}

.modal-login__form label {
    margin-right: 8px;
}

.modal-login__form input {
    padding: 4px 11px !important;
}

.modal-login__policy {
    font-size: 12px;
    margin-bottom: 10px;
}

.modal-login__notification {
    text-align: end;
}
.modal-login__notification .modal-login__notification__label {
    margin-left: 5px !important;
}

@media screen and (max-width: 480px) {
    .modal-login__body {
        min-width: initial;
        max-width: 95%;
    }
}
