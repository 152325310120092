@import '../variables.scss';

#auth-by-code {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto;
  max-width: 400px;
  text-decoration: none;
  color: #414141;
  font-size: 2em;
}

.auth-by-code__list {
  list-style: none;

  li {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .auth-by-code__title {
    margin-top: 10px;
  }

  .auth-by-code__value {

  }
}