@import '../variables.scss';

.premium-block {
  padding: 20px 10px;
  width: 80%;
  background-color: #3C5482;
  margin: 0 auto;
  border-radius: 10px;
  border: 2px solid black;

  .premium-block__description {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    @include breakpoint(xs) {
      display: none;
    }
  }

  .premium-block__icon {
    stroke: #fcfc71;
    stroke-width: 1.5;
    @include breakpoint(xs) {
      display: none;
    }
    @media (max-height: 720px){
      display: none;
    }
  }

  .premium-block__content {
    width: 70%;
    color: white;
    font-size: 14px;
    padding: 5px;
    text-align: center;
    align-self: baseline;
    position: relative;
    bottom: 0;
    @include breakpoint(xs) {
      display: none;
    }
    @media (max-height: 720px){
      display: none;
    }
  }

  .premium-block__btn {
    color: #3C5482;
    background-color: #fcfc71;
    @media (max-height: 768px){
      margin-right: 20px;;
    }
  }

  @include breakpoint(xs) {
    border: none;
  }
  @media (max-height: 768px){
    border: none;
    padding: 20px;
  }
}