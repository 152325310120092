.card-price-container {
  display: flex;
  justify-content: center;

  .card__price {
    height: 150px;
    width: 150px;
    background-color: #fcfc71;
    border-radius: 50%;
    font-size: 48px;
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card__text-price {
      font-size: 36px;
    }

    .card__price-text {
      font-size: 24px;
    }
  }
}