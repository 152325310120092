@import '../variables.scss';

.done-word-search {
  @include card-container;
  width: 100%;
  padding: 24px;
  margin-top: 16px;

  .done-word-search__select {
    width: 350px;
  }
}
@media screen and (max-width: 1280px) {
  .done-word-search {

    .done-word-search__select {
      width: 280px;
    }
  }
}

@media screen and (max-width: 1025px) {
  .done-word-search {

    .done-word-search__select {
      width: 200px;
    }
  }
}

@media screen and (max-width: 890px) {
  .done-word-search {
    display: flex;
    flex-direction: column;
    .done-word-search__select {
      width: 250px;
    }

  }

}


@media screen and (max-width: 770px) {
  .done-word-search {

    .done-word-search__select {
      width: 200px;
    }

  }

}

@media screen and (max-width: 640px) {
  .done-word-search {

    .done-word-search__select-area {
      display: flex;
      flex-direction: column;
    }

    .done-word-search__select {
      width: 200px;
    }

  }

}

@media screen and (max-width: 480px) {
  .done-word-search {

    .done-word-search__select {
      width: 370px;
    }

  }

}

@media screen and (max-width: 460px) {
  .done-word-search {

    .done-word-search__select {
      width: 300px;
    }

  }

}

@media screen and (max-width: 380px) {
  .done-word-search {

    .done-word-search__select {
      width: 250px;
    }

  }

}

@media screen and (max-width: 345px) {
  .done-word-search {

    .done-word-search__select {
      width: 250px;
    }

  }

}

