@import '../variables.scss';

#communities {
  width: 100%;

  .communities__header {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .communities__header__btn {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

  }

  .communities__main {
    margin: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
  }

  .communities__main__item {
    text-decoration: none;
    color: #000000;
    transition: -webkit-transform 0.1s ease-in;
    @include card-container;
    transition: transform 0.1s ease-in;
    transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .communities__main__item--main {
    display: flex;
    gap: 20px;

    img {
      width: 309px;
      height: 157.945px;
      flex-shrink: 0;
    }
  }

  .communities__main__item--header {
    display: flex;
    justify-content: space-between;
  }

  .communities__main__item--footer {
    margin-top: auto;
  }

  .communities__main__item--footer__btns {
    justify-content: space-between;
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
}

@media screen and (max-width: 1920px) {
  #communities {
    .communities__main {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 1600px) {
  #communities {
    .communities__main__item--main {
      flex-direction: column;


    }
  }
}

@media screen and (max-width: 1300px) {
  #communities {
    .communities__main {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 800px) {
  #communities {
    .communities__main__item--footer__btns {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 499px) {
  #communities {
    .communities__main {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }

    .communities__main__item--main {
      img {
        width: 200px;
      }
    }
  }
}



