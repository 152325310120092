@import '../variables.scss';
* {
    box-sizing: border-box;
}

.mobil-auth-container {
    height: 100vh;
    @include center-content;
    background-color: $colorBgWhite;
    padding: 10px;


    .mobil-auth-body {
        max-width: 590px;
        @include center-content;
    }

    .mobil-auth-body__title {
        text-align: center;
    }

    .mobil-auth-body__form {
        display: flex;
        flex-direction: column;
    }

    .login__policy {
        text-align: center;
    }

    .mobil-auth-body__login__link {
        display: block;
        text-align: center;
    }

  .mobil-auth-body__login-registration-btn {
    align-self: center;
    width: 30%;
    margin-bottom: 10px;
  }

    .telegram-login-GeekenglishBot {
        align-self: center;
    }

    .login__notification__label {
        align-self: center;
    }

    .login__notification {
        text-align: end;
    }

    .telegram-btn {
        text-align: center;
    }

    .mobil-auth-body__container-btn {
        display: flex;
        flex-direction: column;
    }

    .container-btn__login__btn {
        border: 0;
        background-color: $colorBgWhite;
        color: $colorBtnBlue;
        cursor: pointer;
        text-align: center;

        &:hover {
            color: $colorBtnHoverBlue;
        }
    }

    .mobil-auth-body__container-btn-registration {
        text-align: center;
    }
}

@media screen and (min-width: 576px) {
    .mobil-auth-body__form {
        width: 550px;
    }
}
