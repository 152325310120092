.checkbox {
  font-size: 16px;

  .ant-checkbox-inner {
    width: 25px;
    height: 25px;
  }

  .ant-checkbox-inner::after {
    width: 9px;
    height: 16px;
  }
}