@import '../../../variables.scss';

.dictionary_right_bar {
  width: 100%;
  margin-top: 30px;
}

.dictionary_right_bar > .dictionary_right_bar--wls {
  display: grid;
  flex-wrap: wrap;
  gap: 30px;
  overflow-x: hidden;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding-left: 16px;
  padding-bottom: 26px;
}

.dictionary_right_bar > .dictionary_right_bar--wls > .dictionary__word-list-item > span {
  padding: 5px;
  border-radius: 3px;
  margin-right: 5px;
}

.dictionary__word-list-item > .word-list-item__list-level {
  display: flex;
  justify-content: space-between;
  width: 98%;
}

.dictionary__word-list-item > .word-list-item__list-image {
  width: 150px;
  height: 110px;
}

.dictionary__word-list-item > .word-list-item__btns {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.dictionary__word-list-item > .word-list-item__admin-btns {
  margin-top: auto;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.dictionary_right_bar--wls .word-list-item__list-name {
  text-align: center;
}

.dictionary_right_bar .dictionary_right_bar--wls .dictionary__word-list-item-selected {
  box-sizing: border-box;
  border: 6px solid $menuColor;
}

.dictionary_right_bar .dictionary__word-list-item {
  box-sizing: border-box;
  border: 6px solid #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  list-style-type: none; /* Убираем маркеры */
  text-decoration: none;
  @include card-container;
  transition: -webkit-transform 0.1s ease-in;
  transition: transform 0.1s ease-in;
  transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
  border-radius: 1em;
  margin: 5px;
}

.dictionary_right_bar input {
  background-color: rgba(42, 169, 224, 0);
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
}

.dictionary_right_bar .dictionary__word-list-item:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  -webkit-transform: scale(1.025);
  transform: scale(1.025);
}

@media screen and (max-width: 1295px) {
  .dictionary_right_bar .dropdown__item:not(:last-child) {
    height: 100px;
  }
}

/* xl (>=1200) */
@media (min-width: 1200px) {
  .dictionary_right_bar > .dictionary_right_bar--wls {
    overflow-y: scroll;
    max-height: calc(100vh - 180px);
  }

  .dictionary_right_bar > .dictionary_right_bar--wls-community {
    overflow-y: scroll;
    max-height: calc(100vh - 400px);
  }
}

@media screen and (max-width: 560px) {
  .dictionary_right_bar .dropdown__item:not(:last-child) {
    height: 110px;
  }

  .dictionary_right_bar > .dictionary_right_bar--wls {
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .dictionary__word-list-item > .word-list-item__list-image {
    width: 110px;
    height: 90px;
  }

  #dictionary .word-list-item__list-name {
    margin: 5px;
  }

  .dictionary_right_bar {
    margin-top: 0;
  }

  .dictionary_left_bar input {
    width: 90%;
  }

  .dictionary_right_bar {
    display: flex;
    justify-content: center;
  }
}
