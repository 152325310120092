@import '../variables.scss';

.courses-overview-author__container {
  @include card-container;
  width: 40%;

  .courses-overview-author__title {
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }

  .courses-overview-author__text {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    font-size: 18px;
    line-height: 24px;
  }

  .courses-overview-author__img {
    width: 90%;
    border-radius: 20px;
  }
}

@media screen and (max-width: 1050px) {
  .courses-overview-author__container {
    width: 100%;
  }
}

