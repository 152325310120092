@import '../variables.scss';

.courses-card__container {
  @include card-container;
  width: 45%;
  padding: 16px;

  .courses-card__card-name {
    font-size: 32px;
    line-height: 48px;
  }

  .courses-card__card-status {
    font-size: 32px;
    line-height: 48px;
  }


  .courses-card__card-container {
    margin-top: 8px;
    margin-bottom: 16px;

    .courses-card__img {
      width: 480px;
      height: 208px;
      border-radius: 20px;
    }

    .courses-card__description {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

@media screen and (max-width: 1640px) {
  .courses-card__container {

    .courses-card__card-name {
      font-size: 32px;
      line-height: 48px;
    }

    .courses-card__card-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .courses-card__img {
        width: 100%;
        border-radius: 20px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .courses-card__container {

    .courses-card__card-name {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .courses-card__container {
    width: 100%;

    .courses-card__card-name {
      font-size: 32px;
      line-height: 48px;
    }

    .courses-card__description {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 780px) {
  .courses-card__container {

    .courses-card__card-name {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

