.month-stats__warning {
    color: #faad14;
    font-size: 16px;
    line-height: 1.2;
    padding: 16px 0;
}

.month-stats {
    height: 500px;
}

.month-stats__table {
    padding: 10px 15px 0 15px;
}

@media screen and (max-width: 1500px) {
    .month-stats {
        height: auto;
        flex-grow: 1;
    }
}
