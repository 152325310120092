@import '../variables.scss';

#reset-password {
  width: 100vw;
  height: 100vh;

  .reset-password__form {
    margin: 200px auto;
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .reset-password__input-email {
    font-size: 2em;
    text-align: center;
  }

  .reset-password__message {
    color: $colorDone;
  }

  .reset-password__error {
    color: $colorError;
  }
}

@media (max-width: 600px) {
  .reset-password__input-email {
    font-size: 17px;
  }
  .reset-password__form {
    width: 80%;
  }
}
