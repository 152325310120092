@mixin card-container {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: -10px 14px 14px 0 rgba(0, 0, 0, 0.15);
}

@mixin text-card {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}


.profile-main-container {
  display: grid;
  grid-template-columns: repeat( auto-fill, 33% );
  margin: 50px 0 0 75px;

}

.user-card-container {
  width: 100%;
  height: 590px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include card-container;

  .profile_photo {
    padding-top: 12px;
    cursor: pointer;
  }

  .profile_photo > img {
    width: 260px;
    height: 260px;
    border-radius: 50%;
  }

  .user-card__text {
    width: 70%;
    color: #8A8A8A;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }

  .user-card__text-user {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    margin-left: 32px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .profile_page_profile__status {
    font-size: 24px;
    color: #ff9300;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-card__dropdown {
    margin-left: 75%;
    margin-top: 16px;
  }
}

.target-card-container {
  width: 100%;
  height: 166px;
  display: flex;
  justify-content: space-evenly;
  @include card-container;
  margin-top: 50px;
  padding-top: 20px;

  .target-card__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 8px;

    .target-card__text {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.target-card-container__1 {
  width: 100%;
  height: 166px;
  display: flex;
  gap: 16px;
  align-items: center;
  @include card-container;
  margin-top: 50px;
  padding-top: 20px;

  .target-card-container__icon {
    margin-left: 16px;
  }

  .target-card-container__text {
    font-size: 24px;

  }
}

.errors-in-words-card-container {
  width: 85%;
  height: 590px;
  @include card-container;
  margin-left: 65px;

  .errors-in-words-card-text {
    margin-top: 36px;
    @include text-card;
  }
}

.repeat-list-card-container {
  width: 85%;
  height: 590px;
  @include card-container;
  margin-left: 30px;

  .repeat-list-card-text {
    margin-top: 36px;
    @include text-card;
  }
}

.words-statistics-card-container {
  width: 85%;
  height: 166px;
  @include card-container;
  margin: 50px 0 0 30px;

  .words-statistics-card-text {
    margin-top: 16px;
    @include text-card;
  }
}

.test-statistics-card-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 166px;
  @include card-container;
  margin: 50px 0 0 65px;

  .test-statistics-card-text {
    margin-top: 16px;
    @include text-card;
  }
}

.modal-input-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

@media screen and (max-width: 1680px) {
  .profile-main-container {
    margin: 50px 0 0 40px;

  }
  .user-card-container {
    height: 550px;

    .profile_photo > img {
      width: 240px;
      height: 240px;
    }

    .user-card__dropdown {
      margin-left: 70%;
    }
  }
  .target-card-container {
    height: 166px;
    padding: 16px;

    .target-card__text {
      font-size: 16px;
    }
  }
  .target-card-container__1 {
    height: 166px;

    .target-card-container__icon {
      margin-left: 16px;
    }

    .target-card-container__text {
      font-size: 22px;
    }
  }


  .errors-in-words-card-container {
    height: 550px;
    margin-left: 35px;
  }
  .repeat-list-card-container {
    height: 550px;
    margin-left: 10px;
  }

  .words-statistics-card-container {
    height: 166px;
    margin: 40px 0 0 20px;

    .words-statistics-card-text {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .test-statistics-card-container {
    height: 166px;
    margin: 40px 0 0 45px;

    .test-statistics-card-text {
      font-size: 18px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 1566px) {

  .target-card-container {
    height: 146px;
    padding: 12px 25px 20px 25px;
    gap: 12px;
    margin-top: 30px;

    .target-card__text {
      font-size: 14px;
    }
  }
  .target-card-container__1 {
    height: 146px;
    padding: 12px 25px 20px 25px;
    gap: 16px;
    margin-top: 30px;

    .target-card-container__icon {
      margin-left: 16px;
    }

    .target-card-container__text {
      font-size: 24px;

    }
  }
  .errors-in-words-card-container {
    width: 100%;
    height: 548px;
    margin-left: 8%;
  }
  .repeat-list-card-container {
    height: 548px;
    margin-left: 16%;

  }

  .words-statistics-card-container {
    height: 146px;
    margin: 24px 0 0 16%;
  }

  .test-statistics-card-container {
    width: 100%;
    height: 146px;
    margin: 30px 0 0 35px;
  }
}

@media screen and (max-width: 1370px) {
  .profile-main-container {
    margin: 20px 0 0 20px;

  }
  .user-card-container {
    .user-card__dropdown {
      margin-left: 58%;
    }

    .profile_photo > img {
      width: 210px;
      height: 210px;
    }

    .user-card__text {
      font-size: 18px;
      line-height: 18px;
    }

    .user-card__text-user {
      font-size: 18px;
      line-height: 18px;
    }
  }

  .target-card-container {
    flex-direction: column;
    align-items: center;
    height: 230px;
    padding: 8px;
    gap: 8px;
    margin-top: 4px;
  }

  .target-card-container__1 {
    height: 196px;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 16px;
    margin-top: -32px;

    .target-card-container__text {
      font-size: 18px;

    }
  }

  .errors-in-words-card-container {
    height: 600px;
    margin-left: 5%;

    .errors-in-words-card-text {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .repeat-list-card-container {
    height: 600px;
    margin-left: 10%;

    .repeat-list-card-text {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .words-statistics-card-container {
    height: 146px;
    margin: 16px 0 0 10%;

    .words-statistics-card-text {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .test-statistics-card-container {
    height: 146px;
    margin: 16px 0 0 5%;

    .test-statistics-card-text {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

@media screen and (max-width: 820px) {
  .profile-main-container {
    margin: 20px 0 0 30px;
    grid-template-columns: repeat( auto-fill, 100% );
    grid-template-rows: repeat(3, 1fr);
  }

  .user-card-container {
    width: 97%;
    height: 500px;

    .profile_photo > img {
      width: 200px;
      height: 200px;
    }

    .user-card__text {
      font-size: 18px;
      line-height: 18px;
    }

    .user-card__text-user {
      font-size: 18px;
      line-height: 18px;
    }

    .user-card__dropdown {
      margin-left: 50%;
    }
  }
  .target-card-container {
    width: 96%;
    height: 136px;
    padding: 8px 25px 8px 25px;
    gap: 12px;
    margin-top: 16px;
    flex-direction: row;
  }

  .target-card-container__1 {
    width: 96%;
    height: 136px;
    padding: 8px 25px 8px 25px;
    gap: 32px;
    margin-top: 16px;
    flex-direction: row;

    .target-card-container__text {
      font-size: 18px;
    }
  }

  .errors-in-words-card-container {
    width: 96%;
    height: 548px;
    margin-left: 4px;

  }
  .repeat-list-card-container {
    width: 96%;
    height: 548px;
    margin-top: 16px;
    margin-left: 4px;
  }

  .words-statistics-card-container {
    width: 96%;
    height: 136px;
    margin: 16px 0 16px 4px;

  }

  .test-statistics-card-container {
    width: 96%;
    height: 136px;
    margin: 16px 0 0 4px;
  }
}

@media screen and (min-width: 100px) and (max-width: 405px) {
  .profile-main-container {
    margin: 16px 0 0 16px;
  }
  .errors-in-words-card-container {
    height: 570px;
  }
}