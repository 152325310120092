#aferta {
    margin: 2em auto;
    width: 80%;
    font-family: BlinkMacSystemFont, -apple-system, segoe ui, Roboto, Oxygen-Sans, Ubuntu, Cantarell, helvetica neue,
        sans-serif;
    list-style: none;
}

#aferta > h2 {
    font-weight: bold;
    text-align: center;
}

#aferta > ul {
    margin-top: 10px;
    list-style: none;
}

#aferta > ul > li > ul {
    list-style: none;
}
