@import '../variables.scss';

#dictionary {
  .word-list-item__list-name {
    margin-bottom: auto;
    font-size: 1.5em;
  }

  .word-list-item__input-list-name {
    margin: 5px;
    font-size: 1.2em;
    color: black;
    background-color: $colorDictionaryInput !important;
    width: calc(100% - 14px);
  }

  .word-list-item__input-list-name-error {
    background-color: rgba(253, 175, 175, 0.72);
  }

.word-list-item__admin-btns {
  margin-top: 16px;
}

.word-list-item__list-image {
  max-width: 100%;
}

.word-list-item__buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: auto;
}
}