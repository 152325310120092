@import '../variables.scss';

#share-list li,
#share-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#share-list {
  text-align: center;
}

#share-list > ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#share-list .share-list_item {
  @include card-container;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  padding: 10px;
  width: 230px;
}

#share-list .share-list_item:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  -webkit-transform: scale(1.025);
  transform: scale(1.025);
}

#share-list .share-list_item > img {
  height: 100px;
}
