@import '../variables.scss';

.done_word_main {
  width: 100%;
  margin: 0 auto 16px;
  padding: 24px;

  .done_word_admin_button {
    margin-top: 20px;
  }

  .done_word_admin_button > span {
    margin-left: 5px;
    font-size: 20px;
    position: relative;
    top: 3px;
  }

  .done_word_lists {
    width: 100%;
    margin: 24px 8px 0 8px;
    padding: 16px;
    overflow-y: scroll;
    max-height: calc(100vh - 255px);
    display: grid;
    grid-template-columns: repeat(auto-fit, 290px);
    grid-gap: 16px;

  }

  .done_word_lists_item {
    height: 390px;
    width: 290px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include card-container;
    transition: -webkit-transform 0.1s ease-in;
    transition: transform 0.1s ease-in;
    transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
  }

  .done_word_lists_item_admin_panel {
    display: grid !important;
    grid-template-columns: 1fr 2fr;
    align-items: center;
  }

  .done_word_lists_item_admin_panel button {
    background-color: #00bcd4;
  }

  .done_word_lists_item {
    padding: 0;
    margin: 0;
  }

  .done_word_lists_item:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
    -webkit-transform: scale(1.025);
    transform: scale(1.025);
  }

  .done_word_lists_item_level {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 8px 8px;
  }

  .done-word-lists-item_footer-card {
    display: flex;
    justify-content: space-between;
  }

  .done_word_lists_item_premium-header {
    border-radius: 8px;
    padding: 5px;
    border: none;
    background-color: #fcfc71;
    color: black;
  }

  .done_word_lists_item_like {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
  }

  .done_word_lists_item_li_author {
    right: 0;
    bottom: 5px;
    margin-right: 24px;
  }

  .done_word_lists_item_name {
    text-align: center;
    font-size: 24px;
  }

  .done_word_lists_item_li_button div {
    display: flex;
    justify-content: center;
  }

  .done_word_lists_item_image {
    display: flex;
    justify-content: center;
  }

  .done_word_lists_item_tags {
    display: flex;
    margin-bottom: 5px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .done_word_lists_item img {
    height: 150px;
    width: 150px;
  }

  .done_word_lists_item .not_allowed_cursor {
    cursor: not-allowed;
  }

  .done_word_lists_item .disabled_button {
    background-color: #7f7f7f;
    box-shadow: 0 2px 5px rgb(10, 10, 10);
  }

  .done_word_lists_item button:hover {
    box-shadow: none;
    -webkit-transform: scale(1.025);
  }

  .done_word_lists_item span {
    margin: 0 auto;
  }

  .done_word_main .done_word_lists_item_image {
    display: flex;
    justify-content: center;
  }

  .done_word_main .done_word_lists_item_name > span {
    text-align: center;
    width: 90%;
    font-size: 2em;
  }

  .done_word_lists_item_level_question {
    padding: 6px 13px !important;
    cursor: pointer;
  }

  .done_word_lists_item_level_question:hover {
    background-color: #e9e9e9;
  }
}

@media screen and (max-width: 860px) {
  .done_word_main {

    .done_word_lists {
      max-height: calc(100vh - 440px);
      grid-template-columns: repeat(auto-fit, 220px);

    }

    .done_word_lists_item {
      width: 220px;
    }
  }
}

@media screen and (max-width: 810px) {
  .done_word_main {

    .done_word_lists {
      grid-template-columns: repeat(auto-fit, 400px);
    }

    .done_word_lists_item {
      width: 400px;
    }
  }
}

@media screen and (max-width: 645px) {
  .done_word_main {
    .done_word_lists {
      grid-template-columns: repeat(auto-fit, 199px);
    }

    .done_word_lists_item {
      width: 199px;
    }

  }
}

@media screen and (max-width: 480px) {
  .done_word_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    .done_word_lists {
      grid-template-columns: repeat(auto-fit, 340px);
    }

    .done_word_lists_item {
      width: 340px;
    }

    .done_word_submit_your {
      width: 100%;
      display: flex;
      justify-content: end;
    }
  }
}

@media screen and (max-width: 420px) {
  .done_word_main {

    .done_word_lists {
      grid-template-columns: repeat(auto-fit, 315px);
    }

    .done_word_lists_item {
      width: 315px;
    }
  }
}

@media screen and (max-width: 400px) {
  .done_word_main {

    .done_word_lists {
      grid-template-columns: repeat(auto-fit, 285px);
    }

    .done_word_lists_item {
      width: 285px;
    }
  }
}

@media screen and (max-width: 365px) {
  .done_word_main {

    .done_word_lists {
      grid-template-columns: repeat(auto-fit, 270px);
    }

    .done_word_lists_item {
      width: 270px;
    }
  }
}

@media screen and (max-width: 345px) {
  .done_word_main {

    .done_word_lists {
      grid-template-columns: repeat(auto-fit, 250px);
    }

    .done_word_lists_item {
      width: 250px;
    }
  }
}

