@import '../variables.scss';

.course-item__container {
  @include card-container;
  padding: 16px;
  cursor: pointer;


  .course-item__icon-container {
    width: 64px;
    height: 64px;
  }

  .course-item__text {
    font-size: 18px;
    font-weight: 300;
  }

  .course-item__text-time {
    font-size: 16px;
    font-weight: 300;
  }

  .course-item__checked {
    display: flex;
    justify-content: end;
    flex-basis: calc(90%);
  }

}

//@media screen and (max-width: 1280px) {
//  #courses {
//    width: 90%;
//    flex-direction: column;
//    margin: 32px;
//  }
//}
//
//@media screen and (max-width: 780px) {
//  #courses {
//    margin: 16px;
//  }
//}
