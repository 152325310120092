@import '../variables.scss';

.dictionary_container > .dictionary_main {
  width: 100%;
}

.dictionary_container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 96%;
}

.dictionary_btn {
  margin-left: 46px;
}

.active_dictionary_category {
  margin-left: 20px !important;
  margin-right: 50px !important;
}

.dictionary_main > .word_list {
  margin-top: 10px;
  color: black;
  font-size: 1.3em;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
}

.dictionary_main .word_item {
  width: 95%;
  font-size: 1.3em;
  margin-top: 10px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 0.5fr;
}

.dictionary_main .word_list_eng {
  border-radius: 10px;
  font-size: 1em;
  border: none;
  text-align: center;
  padding: 3px 10px;
  background-color: #e03366;
  word-wrap: break-word;
  min-height: 100% !important;
  overflow-y: hidden;
}

.dictionary_main .word_list_rus {
  border-radius: 10px;
  font-size: 1em;
  border: none;
  padding: 3px 10px;
  text-align: center;
  background-color: #1677ff;
  word-wrap: break-word;
  min-height: 100% !important;
  overflow-y: hidden;
}

.dictionary_main .word_list_rus:first-letter {
  text-transform: uppercase;
}

.dictionary_main .word_list_eng:first-letter {
  text-transform: uppercase;
}

.dictionary_main .word_item .word_item_image {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin: 5px
}

.dictionary_main.word_item img {
  float: right;
  cursor: pointer;
  margin-top: 6px;
  height: 20px;
  width: 20px;
}

.dictionary_main .word_item_add_word {
  cursor: pointer;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  margin-top: 10px;
  background-color: #5c3191;
}

.dictionary_active_input_style {
  color: black !important;
  padding: 6px !important;
  background-color: rgb(239, 239, 239) !important;
  border: solid 1px black !important;
}

.dictionary_user_header_message {
  text-align: center;
  padding: 1px;
  background-color: #6dffc0;
  position: absolute;
  width: 100%;
}

.dictionary_user_header_message_red {
  text-align: center;
  padding: 1px;
  background-color: red;
  position: absolute;
  width: 100%;
}

.dictionary .community {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 90px;
  padding: 0;
  margin-bottom: 50px;
  align-items: flex-start;
  justify-content: flex-start;
}

.dictionary > .community .buttons {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}


@keyframes height-animate {
  from {
    height: 30px;
  }

  to {
    height: 180px;
  }
}

.dictionary .dropdown .dropdown__list {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: calc(100vw - 260px);
  overflow-x: scroll;

}

.dictionary .dropdown .loading_cycle .loader {
  position: absolute;
  z-index: 1;
  left: 200%;
  top: 90%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  width: 40px;
  height: 40px;
}

.dictionary .dropdown .dropdown__list-loading {
  display: flex;
  justify-content: center;
  position: relative;
  height: 40px;
  width: 40px;
}

.dictionary .dropdown .dropdown__item > img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.dictionary .dropdown .dropdown__item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.dictionary .dropdown .dropdown__item-active {
  background-color: #529BF9;
  border-radius: 10px;
}

.dictionary .dropdown .dropdown__item:hover {
  background-color: rgba(129, 129, 129, 0.42);
}

.dictionary .dropdown .dropdown__item > span {
  text-align: center;
}

.dictionary .dropdown .dropdown__item:hover .community__item {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
}

@keyframes opacity-animate {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* xl (>=1200) */
@media (min-width: 1200px) {
  .dictionary_main > .word_list {
    overflow-y: scroll;
    max-height: calc(100vh - 220px);
  }
}

@media screen and (max-width: 1250px) {
  .dictionary_container {
    flex-direction: column-reverse;
  }

  .word_item img {
    margin-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .dictionary_main .word_item {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    grid-auto-flow: column;
    border-bottom: 1px solid black;
  }

  .dictionary_main .word_item-disabled-editing {
    padding-bottom: 40px;
  }

  .dictionary .community {
    margin: 15px 0 50px 0;
  }
}

@media screen and (max-width: 560px) {
  .dictionary .dropdown .dropdown__list {
    width: 100vw;
  }

  .dictionary_btn {
    margin-left: 10px;
    justify-content: center;
  }


  .dictionary .community {
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
    margin: 15px 0 15px 0;
  }
}