@import '../variables.scss';

.courses-overview-header__container {
  @include card-container;
  justify-content: space-between;
  padding: 24px;

  .courses-overview-header__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 24px;


    .courses-overview-header__card-name {
      font-size: 48px;
      line-height: 64px;
    }

    .courses-overview-header__price-text {
      font-size: 24px;
      line-height: 36px;
    }

    .price {
      color: #FF0000;
    }

    .oldPrice {
      text-decoration-line: line-through;
      -webkit-text-decoration-line: line-through;
      text-decoration-color: red;
      -webkit-text-decoration-color: red;
    }
  }

  .courses-overview-header__img {
    width: 43%;
    border-radius: 20px;
  }

  .courses-overview-header__buy {
    height: 25%;
    font-size: 24px;
  }
}

@media screen and (max-width: 1250px) {
  .courses-overview-header__container {
    .courses-overview-header__buy {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1050px) {
  .courses-overview-header__container {
    .courses-overview-header__buy {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1030px) {
  .courses-overview-header__container {
    .courses-overview-header__content {
      .courses-overview-header__card-name {
        font-size: 24px;
        line-height: 36px;
      }

      .courses-overview-header__price-text {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .courses-overview-header__buy {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 970px) {
  .courses-overview-header__container {
    justify-content: center;

    .courses-overview-header__img {
      display: none;

    }
  }

}

@media screen and (max-width: 735px) {
  .courses-overview-header__container {
    .courses-overview-header__content {
      .courses-overview-header__card-name {
        font-size: 16px;
        line-height: 36px;
      }

      .courses-overview-header__price-text {
        font-size: 16px;
        line-height: 24px;
      }

    }

    .courses-overview-header__buy {
      font-size: 14px;
    }
  }
}

