@import '../variables.scss';

#courses {
  padding: 0 0 0 24px;
  width: 96%;

  .courses__description-area {
    width: 60%;

    .courses__description {
      font-size: 24px;
      line-height: 36px;
      font-weight: 300;
    }
  }

  .courses__collapse {
    background-color: #3C5482;

    .ant-collapse-item {
      font-size: 18px;
    }
    .ant-collapse-header {
      color: #FFFFFF;
    }
  }
}

@media screen and (max-width: 1285px) {
  #courses {
    width: 92%;
    .courses__details-area {
      flex-wrap: wrap;
    }
  }
}
@media screen and (max-width: 1050px) {
  #courses {
    width: 90%;
    .courses__content {
      flex-direction: column;
    }

    .courses__description-area {
      width: 100%;
    }
  }
}

@media screen and (max-width: 905px) {
  #courses {
    width: 84%;
  }
}

@media screen and (max-width: 735px) {
  #courses {
    .courses__description-area {
      .courses__description {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

