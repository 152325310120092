.success-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 5px 30px;
}

.success-bar__cards {
    display: flex;
    flex-direction: column;
}

.success-bar__heading {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.success-bar__text {
    color: #04E000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@media screen and (max-width: 1680px) {
    .success-bar__text {
        font-size: 18px;
        line-height: 18px;
    }
    .success-bar__heading {
        font-size: 18px;
        line-height: 18px;
    }
}

@media screen and (max-width: 1262px) {
    .success-bar__text {
        font-size: 16px;
        line-height: 18px;
    }
    .success-bar__heading {
        font-size: 16px;
        line-height: 18px;
    }
}