@import '../../variables.scss';

#premium {

  .premium__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 45px;

    h1 {
      color: #000;
      /* H1 */
      font-family: Source Sans Pro;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px; /* 112.5% */
      margin: 10px;
    }

    h2 {
      color: #000;
      /* H3 */
      font-family: Source Sans Pro;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 133.333% */
    }
  }

  .premium__card-area {
    margin-top: 163px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 30px;
    margin-left: 16px;
  }

  .premium__buying-card {
    position: relative;
    width: 31%;
    height: 341px;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    box-shadow: 14px 14px 20px 0px rgba(0, 0, 0, 0.15);
  }

  .premium__buying-card__price {
    height: 200px;
    width: 200px;
    position: absolute;
    background-color: #fcfc71;
    border-radius: 50%;
    padding-top: 66px;
    top: -100px;
    left: 29%;
    font-size: 56px;
    text-align: center;
    font-weight: 600;
  }

  .premium__buying-card__price-text {
    font-size: 24px;
  }

  .premium__buying-card__content {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .premium__buying-card__title-area {
    display: flex;
    flex-direction: column;


    .premium__buying-card__title {
      color: #000;
      /* Н2 */
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 64px; /* 133.333% */
    }

    .premium__buying-card__sub-title {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 150% */
    }
  }

  .premium__buying-card__sub-content {
    display: flex;
    height: 210px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .premium__buying-card__btn {
    margin-bottom: 16px;
    display: flex;
    width: 347px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    padding: 26px;
  }

  .premium__video-area {
    display: flex;
    justify-content: center;
    margin-top: 135px;
    padding: 0 40px;
    gap: 30px;

    .premium__video-content {
      width: 909px;
      height: 522px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }


    .premium__video-title {
      width: 643px;
      height: 225px;
      flex-shrink: 0;
      color: var(--, #3b3b3b);
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px; /* 112.5% */
    }

  }

  .premium__icons-area {
    display: flex;
    justify-content: space-around;
    margin-top: 110px;

    .premium__icon-content {
      display: flex;
      align-items: center;
      gap: 33px;

      span {
        margin-top: 32px;
        color: #000;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 150% */
        width: 373px;
      }
    }
  }

  .premium__footer {
    margin-top: 80px;
    width: 100%;
    background: var(--, #3C5482);
    height: 139px;
    display: flex;
    justify-content: center;
    align-items: center;

    .premium__footer-help {
      color: whitesmoke;
      cursor: pointer;
    }

    .premium__footer-aferta {
      color: whitesmoke;
    }

    div {
      display: flex;
      flex-direction: column;

      color: #FFFAFA;
      font-family: Source Sans Pro;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 1780px) {
  #premium {
    .premium__buying-card {
      height: 301px;
    }

    .premium__buying-card__price {
      height: 180px;
      width: 180px;
      font-weight: 600;
    }

    .premium__buying-card__price-text {
      font-size: 16px;
    }

    .premium__buying-card__title-area {
      .premium__buying-card__title {
        font-size: 48px;
      }

      .premium__buying-card__sub-title {
        font-size: 18px;
        line-height: 12px; /* 133.333% */
      }
    }

    .premium__buying-card__btn {
      width: 300px;
    }

    .premium__video-area {
      .premium__video-content {
        width: 55%;
        height: 50%;
      }

      .premium__video-title {
        font-size: 56px;
        width: 45%;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  #premium {
    .premium__buying-card__title-area {
      .premium__buying-card__title {
        font-size: 40px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  #premium {
    .premium__buying-card {
      height: 281px;
    }

    .premium__buying-card__price {
      height: 170px;
      width: 170px;
      font-size: 48px;
      font-weight: 600;
    }

    .premium__buying-card__price-text {
      font-size: 16px;
    }

    .premium__buying-card__title-area {
      .premium__buying-card__title {
        font-size: 36px;
      }

      .premium__buying-card__sub-title {
        font-size: 18px;
        line-height: 12px; /* 133.333% */
      }
    }

    .premium__buying-card__btn {
      width: 250px;
    }

    .premium__video-area {
      .premium__video-title {
        font-size: 52px;
      }
    }

    .premium__icons-area {
      .premium__icon-content {
        span {
          font-size: 20px;
          line-height: 28px; /* 150% */
          width: 253px;
        }
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  #premium {
    .premium__buying-card__title-area {
      .premium__buying-card__title {
        font-size: 30px;
      }
    }

    .premium__buying-card__btn {
      width: 230px;
    }

    .premium__video-area {
      .premium__video-title {
        font-size: 48px;
      }
    }

    .premium__icons-area {
      .premium__icon-content {
        span {
          font-size: 20px;
          width: 96%;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  #premium {
    .premium__buying-card__price {
      left: 25%;
    }

    .premium__video-area {
      .premium__video-title {
        font-size: 40px;
        line-height: 56px; /* 133.333% */
      }
    }
  }
}

@media screen and (max-width: 480px) {
  #premium {
    .premium__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 36px;

      h1 {
        font-size: 32px;
        line-height: 48px; /* 112.5% */
        margin: 10px;
      }

      h2 {
        font-size: 18px;
        line-height: 24px; /* 133.333% */
      }
    }

    .premium__card-area {
      margin-top: 16px;
      flex-direction: column;
      gap: 36px;
      margin-left: 8px;
    }

    .premium__buying-card {
      width: 98%;
      height: 158px;
      display: flex;
      justify-content: end;

    }

    .premium__buying-card__price {
      height: 110px;
      width: 110px;
      padding-top: 36px;
      top: 16px;
      left: 3%;
      font-size: 32px;
      font-weight: 400;
    }

    .premium__buying-card__price-text {
      font-size: 16px;
    }

    .premium__buying-card__content {
      width: 67%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .premium__buying-card__title-area {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      margin-top: 10%;
      margin-right: 8px;


      .premium__buying-card__title {
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
      }

      .premium__buying-card__sub-title {
        font-size: 16px;
      }
    }

    .premium__buying-card__sub-content {
      justify-content: start;
      gap: 16px;
    }

    .premium__buying-card__btn {
      margin-right: 8px;
      width: 170px;
      justify-content: center;
      align-items: center;
      font-size: 18px;
    }

    .premium__video-area {
      display: none;

    }

    .premium__icons-area {
      display: none;
    }

    .premium__card-area__1 {
      display: none;
    }

    .premium__footer {
      height: 90px;

      div {
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
}
