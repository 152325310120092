@font-face {
    font-family: Source Sans Pro; /* Гарнитура шрифта */
    src: url(../../fonts/SourceSansPro-Regular.ttf); /* Путь к шрифту */
}

body {
    font-family: Source Sans Pro, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #e9e9e9;
}

.main-layout {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
    width: 100%;
    overflow-y: hidden;
}

.main-layout__main-aside {
    display: flex;
    height: 100vh;
}

.main-layout__main {
    flex-grow: 1;
    overflow-y: scroll;
    height: 100vh;
}

#main {
    transition: margin-left 0.5s;
    overflow: hidden;
    width: 100%;
}

.scale-btn:hover {
    box-shadow: none;
    -webkit-transform: scale(1.025);
}


.main-layout__sidebar {
    flex-basis: 63px;
    flex-grow: 0;
    order: -1;
}

.main-layout__footer {
    flex-grow: 0;
    order: 1;
}

.font-size-15-px {
    font-size: 15px;
}

.red-text-color {
    color: red;
}

.pointer-cursor {
    cursor: pointer;
}

.ellipse-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.margin10right {
    margin-right: 10px;
}

.margin10left {
    margin-left: 10px;
}

.marginMinus2top {
    margin-top: -2px;
}

.margin10 {
    margin: 10px;
}

.margin5top {
    margin-top: 5px;
}

.margin10top {
    margin-top: 10px;
}

.margin20bottom {
    margin-bottom: 20px;
}

.margin30bottom {
    margin-bottom: 30px;
}

.padding10Left {
    padding-left: 10px;
}

.padding10 {
    padding: 10px;
}

.textAlignCenter {
    text-align: center;
}

.yellowBackGround {
    background-color: #ffe000;
}

.yellowBtnBackGround {
    background-color: #fcfc71;
}

.blueBackGround {
    background-color: #4583ff;
}

.greenBackGround {
    background-color: #b9f38f;
}

.width170px {
    width: 170px;
}

.width270px {
    width: 270px;
}

.marginAuto {
    margin: auto;
}

.wrapper a {
    text-align: center;
    margin: 10px auto;
    display: block;
    width: 90%;
    background-color: #e03366;
}

.rus:first-letter {
    text-transform: uppercase;
}

.ge-invisible {
    display: none !important;
}

.opacity-main-fon {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.4;
    z-index: -5;
}

.flexbox-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.medium-font-size {
    font-size: medium;
}

.width105px {
    width: 105px;
}

.width124px {
    width: 124px;
}

.width180px {
    width: 180px;
}

.GELink {
    color: #1677ff;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb {
    background: #243661;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: #fff;
}

@media screen and (max-width: 640px) {
    .mobile-none {
        display: none !important;
    }
}

@media screen and (max-width: 480px) {
    .main-layout__main {
        height: calc(100vh - 63px);
    }

    .main-layout__main-aside {
        height: calc(100vh - 63px);
    }
}
