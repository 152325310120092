#statistic .top-stats {
    display: flex;
    flex-flow: column nowrap;
    border-radius: 5px;
    background: #fff;
    width: calc(100% / 2);
    height: calc(100% - 10px);
    padding: 5px 10px !important;
}

#statistic .top-stats__heading {
    font-size: 24px;
    line-height: 1;
    height: 30px;
}

#statistic .top-stats__list {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    min-height: calc(100% - 30px - (2 * 5px));
    list-style-type: none;
    gap: 5px;
    padding: 5px !important;
}

#statistic .top-stats__list-item {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;
    width: 45%;
}

#statistic .top-stats__users-wrapper {
    display: inline-flex;
    flex-flow: column nowrap;
    width: calc(100% - 20px);
    background: #e9e9e9;
    border-radius: 3px;
}

#statistic .top-stats__user {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 2px 5px !important;
}

#statistic .top-stats__position {
    font-size: 18px;
    line-height: 22px;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 50%;
    background: #e9e9e9;
}

#statistic .top-stats__text {
    font-size: 1rem;
    font-weight: 400;
    height: 24px;
    line-height: 20px;
}

/* Модификаторы */
.position-top {
    color: #3ed468;
}

.list-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1100px) {
    #statistic .top-stats__list-item {
        width: auto;
    }
}

@media screen and (max-width: 1100px) {
    #statistic .top-stats {
        width: 100%;
    }
}
