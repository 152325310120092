.premium-button-container {
  margin-top: 24px;
.premium_button {
  display: flex;
  align-items: center;
  width: 250px;
  height: 46px;
  background-color: #ff9300;
  color: var(--character-primary-inverse, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
}