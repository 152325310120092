@import '../variables.scss';

#create-course {
  height: 100vh;
  background-image: linear-gradient(
                  45deg,
                  hsl(0deg 0% 100%) 1%,
                  hsl(227deg 21% 95%) 39%,
                  hsl(227deg 21% 89%) 47%,
                  hsl(227deg 21% 84%) 50%,
                  hsl(226deg 21% 79%) 51%,
                  hsl(226deg 21% 73%) 51%,
                  hsl(225deg 21% 68%) 50%,
                  hsl(225deg 21% 63%) 49%,
                  hsl(224deg 21% 58%) 49%,
                  hsl(223deg 21% 53%) 50%,
                  hsl(222deg 23% 48%) 53%,
                  hsl(221deg 29% 43%) 61%,
                  hsl(219deg 37% 37%) 99%
  );
}
