.month-wl_warning {
    display: flex;
    justify-content: center;
    padding: 48px 16px;
}

.month-wl__warning {
    color: #faad14;
    font-size: 18px;
}

@media screen and (max-width: 1500px) {
    .month-wl-stats {
        height: auto;
    }
}
