#lp {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    font-size: 10px;
    line-height: 1.2;
    margin: 0;
    padding: 0;
}

#lp .content button,
#lp header button {
    border: none;
}

#lp p,
#lp .content span,
#lp header span,
#lp h1,
#lp h2,
#lp h3,
#lp ul {
    margin: 0;
    padding: 0;
}

#lp .header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 64px;
    width: 100%;
    padding: 0 16px;
    background: transparent;
    z-index: 2;
    transition: background-color 200ms ease;
}

#lp .header-scrolled {
    background: #e9e9e9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

#lp .header-block {
    display: flex;
    flex-direction: column;
    align-items: start;
}

#lp .header__btn {
    font-size: 2em;
    text-decoration: none;
    margin-left: 32px;
    padding: 6px 16px;
    border-radius: 4px;
    background-color: whitesmoke;
    color: black;
}

#lp .header__title {
    font-size: 1.8rem;
    font-weight: 700;
}

#lp .header__text {
    font-size: 1.2rem;
    font-weight: 600;
}

#lp .header__logo {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
}

#lp .header__img {
    height: 48px;
    margin-right: 8px;
}

#lp .header__button-primary {
    background: #32407a;
    font-size: 1.2rem;
    font-weight: 400;
    border-radius: 29px;
    color: #e9e9e9;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 8px 24px;
    cursor: pointer;
    transition: 150ms ease;
}

#lp .header__button-primary:hover {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

#lp .content {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
}

#lp .content__slider {
    height: 100vh;
}

#lp .slider {
    display: flex;
    align-items: center;
    background: transparent;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#lp .slider__background {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    z-index: -1;
    transition: left 0.75s ease;
}

#lp .slider__item {
    flex: 1 1 0;
    height: 100%;
}

#lp .slider__content {
    display: flex;
    flex-flow: row nowrap;
    width: 80%;
    min-height: 50%;
    margin: 0 auto;
}

#lp .slider__img {
    flex-basis: 400px;
    height: 400px;
    margin-right: 24px;
}

#lp .slider__text-wrap {
    display: inline-flex;
    flex-flow: column nowrap;
    padding: 0 80px;
}

#lp .slider__button-container {
    width: 100%;
    padding: 24px 0;
}

#lp .slider__button {
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 400;
    border-radius: 29px;
    color: #e9e9e9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
    padding: 16px 24px;
    transition: 150ms ease;
}

#lp .slider__button:hover {
    top: -3px;
}

#lp .slider__text {
    font-size: 2.4rem;
    min-height: 30%;
}

#lp .slider__cursor {
    font-size: inherit;
    color: #0a0a0a;
    animation: cursor-flashing 1s linear infinite;
}

#lp .content__tips {
    position: relative;
    width: 100%;
    margin: 64px auto 128px auto;
    min-height: 400px;
}

#lp .tips {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
}

#lp .tips__tip {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background: #fff;
    padding: 16px;
    height: 100%;
    width: 400px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5);
}

#lp .tips__tip:first-child {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    margin-right: 8px;
}

#lp .tips__tip:last-child {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    margin-left: 8px;
}

#lp .tips__title {
    color: #32407a;
    height: 50%;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
}

#lp .tips__text {
    color: #32407a;
    min-height: 50%;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.6rem;
}

#lp .content__cards,
#lp .content__rule-cards,
#lp .content__word-cards,
#lp .content__statistic,
#lp .content__register,
#lp .content__reviews {
    width: 80%;
    margin: 0 auto 128px auto;
}

#lp .content__cards-heading,
#lp .content__rule-cards-heading,
#lp .content__word-cards-heading,
#lp .content__statistic-heading,
#lp .content__reviews-heading {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 64px;
}

#lp .content__rule-cards-heading u {
    cursor: pointer;
}

#lp .content__register {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

#lp .content__register__btn {
    display: flex;
    justify-content: center;
}

#lp .content__register__btn > button {
    font-size: 1.2rem;
    padding: 20px 40px 45px 40px;
}


#lp .content__reviews {
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    justify-content: center;
}

#lp .review {
    position: relative;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    min-height: 220px;
    align-self: center;
    gap: 16px;
}

#lp .review-btn {
    position: absolute;
    top: 0;
    width: 54px;
    height: 100%;
}

#lp .review-btn-left {
    left: -54px;
}

#lp .review-btn-right {
    right: -54px;
}

#lp .review-card {
    --avatar-size: 48px;
    --avatar-margin: 12px;
    display: inline-flex;
    flex-flow: row nowrap;
    width: 600px;
    padding: 8px 12px;
    background: whitesmoke;
    border-radius: 6px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}

#lp .review-card-flashing {
    animation: flash-review-card 500ms ease-in-out;
}

@keyframes flash-review-card {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#lp .review-card__avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
    margin-right: var(--avatar-margin);
    /*background: #4CAF50;*/
    border-radius: 50%;
}

#lp .review-card__content {
    display: inline-flex;
    flex-flow: column nowrap;
    width: calc(100% - var(--avatar-size) - var(--avatar-margin));
}

#lp .review-card__title {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 48px;
}

#lp .review-card__body {
    width: 100%;
    padding: 12px 0;
}

#lp .review-card__author {
    font-size: 1.6rem !important;
}

#lp .review-card__login {
    font-size: 1rem !important;
    margin: 0;
}

#lp .review-card__text {
    font-size: 1.3rem !important;
    margin: 0;
}

#lp .cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

#lp .cards__card {
    position: relative;
    top: 0;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 16px 16px 0;
    width: 300px;
}

#lp .rule-card {
    margin: 0 16px 16px 0;
}

#lp .cards__title {
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 8px;
}

#lp .cards__text {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    padding: 16px;
}

#lp .cards__image {
    width: 100%;
    margin-bottom: 8px;
}

#lp .cards__card:last-child {
    margin: 0 0 16px 0;
}

#lp .card-active {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 360px;
    width: 300px;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
    cursor: pointer;
    transition: 200ms ease;
}

#lp .card-active:hover {
    top: -10px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.8);
}

#lp .background-card-title,
#lp .background-card-text {
    width: 100%;
    background: rgba(230, 230, 230, 0.75);
}

#lp .card-active .cards__image {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
}

#lp .statistic-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

#lp .responsive-statistic-container {
    min-width: 400px;
}

#lp .footer {
    width: 100%;
    padding: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#lp .footer__content,
#lp .footer__item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

#lp .footer__item {
    margin-bottom: 16px;
}

#lp .footer__title {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 16px;
}

#lp .footer__text {
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 8px;
}

#lp .footer__social-icons {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
}

#lp .footer__icon {
    height: 40px;
    width: 40px;
}

#lp .contacts {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

#lp .contacts__item {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
}

#lp .contacts__text {
    font-size: 1.3rem;
    font-weight: 400;
    margin-right: 16px;
}

#lp .contacts__link {
    font-size: 1.3rem;
    font-weight: 400;
    color: #333;
}

#lp .footer__rights {
    font-size: 1.6rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
}

#lp .up-button-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    cursor: pointer;
}

#lp .up-button-wrap:hover {
    background: rgba(0, 0, 0, 0.1);
}

#lp .up-button-img {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

@keyframes cursor-flashing {
    0% {
        opacity: 0;
    }
    49.9% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    99.9% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 1550px) {
    #lp .content__reviews {
        width: min-content;
    }
}

@media screen and (min-width: 931px) and (max-width: 1240px) {
    #lp .content__cards,
    #lp .content__rule-cards,
    #lp .content__word-cards,
    #lp .content__statistic {
        width: 90%;
        margin: 0 auto 64px auto;
    }

    #lp .review-card {
        width: 500px;
    }

    #lp .content__tips {
        margin: 64px auto 64px auto;
    }

    #lp .tips__tip {
        width: 300px;
    }

    #lp .tips__title {
        font-size: 1.4rem;
    }

    #lp .tips__text {
        font-size: 1.2rem;
    }

    #lp .cards__title {
        font-size: 1.4rem;
    }

    #lp .cards__text {
        font-size: 1.1rem;
    }

    #lp .review-card__author {
        font-size: 1.4rem !important;
    }

    #lp .review-card__login {
        font-size: 0.9rem !important;
    }

    #lp .review-card__text {
        font-size: 1.2rem !important;
    }
}

@media screen and (max-width: 930px) {
    #lp .review-card {
        width: 400px;
    }

    #lp .slider__text {
        margin-bottom: 16px;
        min-height: 300px;
    }

    #lp .slider__img {
        display: none;
    }

    #lp .tips__tip {
        width: 250px;
    }

    #lp .cards__card {
        width: 220px;
    }

    #lp .cards__image {
        width: 150px;
    }

    #lp .tips__title {
        font-size: 1.3rem;
    }

    #lp .tips__text {
        font-size: 1.1rem;
    }

    #lp .cards__title {
        font-size: 1.3rem;
    }

    #lp .cards__text {
        font-size: 1rem;
    }

    #lp .review-card__author {
        font-size: 1.3rem !important;
    }

    #lp .review-card__login {
        font-size: 0.8rem !important;
    }

    #lp .review-card__text {
        font-size: 1.1rem !important;
    }
}

@media screen and (max-width: 780px) {
    #lp .review-card {
        width: 330px;
    }

    #lp .tips__tip {
        width: 200px;
        text-align: center;
    }

    #lp .tips__title {
        font-size: 1.2rem;
    }

    #lp .tips__text {
        font-size: 1rem;
    }

    #lp .cards__title {
        font-size: 1.2rem;
    }

    #lp .cards__text {
        font-size: 1rem;
    }

    #lp .content__tips {
        min-height: 300px;
    }

    #lp .review-card__author {
        font-size: 1.2rem !important;
    }

    #lp .review-card__login {
        font-size: 0.8rem !important;
    }

    #lp .review-card__text {
        font-size: 1rem !important;
    }
}

@media screen and (max-width: 630px) {
    #lp .content__cards,
    #lp .content__rule-cards,
    #lp .content__word-cards,
    #lp .content__statistic {
        width: 95%;
        margin: 0 16px 48px 16px;
    }

    #lp .review-card {
        width: 280px;
    }

    #lp .header__title {
        font-size: 22px;
    }

    #lp .header__text {
        font-size: 14px;
    }

    #lp .header__img {
        height: 32px;
    }

    #lp .header__button-primary,
    #lp .slider__button {
        font-size: 14px;
    }

    #lp .slider__text {
        font-size: 30px;
    }

    #lp .content__tips {
        min-height: 400px;
    }

    #lp .tips__tip {
        width: 140px;
        text-align: center;
    }

    #lp .tips__title {
        font-size: 0.9rem;
    }

    #lp .tips__text {
        font-size: 0.8rem;
    }

    #lp .card-active {
        height: 300px;
    }

    #lp .cards__title {
        font-size: 1.1rem;
    }

    #lp .cards__text {
        font-size: 0.9rem;
    }

    #lp .cards__image {
        width: 120px;
    }

    #lp .contacts__text,
    #lp .contacts__link {
        font-size: 18px;
    }

    #lp .up-button-wrap {
        width: 50px;
    }

    #lp .up-button-img {
        width: 24px;
        height: 24px;
    }

    #lp .review-card__author {
        font-size: 1rem !important;
    }

    #lp .review-card__text {
        font-size: 0.85rem !important;
    }

    #lp .header__btn {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    #lp .review-card {
        width: 250px;
    }

    #lp .slider__text-wrap {
        width: 100%;
        padding: 0;
    }

    #lp .slider__text {
        min-height: 250px;
    }

    #lp .header__title {
        font-size: 1rem;
        font-weight: 700;
    }

    #lp .header__text {
        font-size: 0.7rem;
    }

    #lp .tips {
        flex-flow: column nowrap;
    }

    #lp .tips__tip {
        width: 280px;
        border-radius: 8px;
        padding: 8px;
        margin-bottom: 12px;
    }

    #lp .tips__title {
        margin-bottom: 8px;
    }

    #lp .tips__tip:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        margin-right: 0;
    }

    #lp .tips__tip:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-left: 0;
    }

    #lp .responsive-statistic-container {
        min-width: 300px;
    }
}
