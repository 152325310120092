@import '../../variables.scss';

#premium {
  .premium__timer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    font-size: 1.4em;

    .col-4 > .box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .premium__promo-code {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
    max-width: 400px;
  }

  .premium__header {
    margin: 10px auto;
    text-align: center;
  }

  .premium__sub-header {
    text-align: center;
  }

  .premium__variant-cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
    gap: 20px;

    .premium__aferta {
      margin: 5px;
      font-size: 14px;
    }

    .premium__variant-card {
      border: #5a5683 1px solid;
      font-size: 1.3em;
      min-height: 180px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 350px;
      background-color: white;
      text-decoration: none;
      color: #414141;
      border-radius: 15px;
      list-style: none;
      padding-left: 0;
      padding-right: 5px;

      .premium__variant-card__list {
        line-height: 1.7;
        display: flex;
        justify-content: left;
        flex-direction: column;
        list-style: none;
        margin-left: 0;
      }

      b {
        font-weight: 900;
        font-size: 1.3em;
      }

      span {
        text-align: center;
      }

    }
  }

  .premium__later {
    font-size: 1.5em;
    text-align: center;
    display: block;
    margin: 10px auto;
  }

  .premium__question {
    text-align: center;
    display: block;
    margin: 10px auto;
  }

  .marginTop20 {
    margin: 20px auto;
  }
}