@import '../variables.scss';

#course {
  gap: 16px;
  width: 98%;
  padding: 48px 0 0 32px;

  .course__module-title {
    font-size: 48px;
  }

  .course__video-container{
    width: 68%;
    padding: 4px;
  }

  .course__btn-area {
    width: 96%
  }

  .course__list-container {
    width: 32%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .course__module-description {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
    width: 94%;
  }

  .course__progress-container{
    @include card-container;
    padding: 16px;
  }

  .course__progress-title {
    font-size: 36px;
  }

  .course__progress-quote {
    font-size: 24px;
    font-weight: 300;
  }

  .course__module-title-list {
    font-size: 36px;
  }
}

@media screen and (max-width: 1810px) {
  #course {
    .course__module-container {
      flex-direction: column;
    }

    .course__video-container {
      width: 100%;
    }
    .course__module-video-container {
      display: flex;
      justify-content: center;
    }
    .course__btn-area {
      width: 85%;
    }
    .course__module-description {
      width: 100%;
    }
    .course__list-container {
      width: 100%;
      //flex-direction: row;
      justify-content: center;
      margin-top: 18px;
    }
    .course__progress-container{
      height: 12%;
    }
  }
}

@media screen and (max-width: 1280px) {
  #course {
    .course__btn-area {
      width: 100%;
    }
  }
}

@media screen and (max-width: 810px) {
  #course {
    .course__list-container {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 480px) {
  #course {
    padding: 16px;
    .course__module-title {
      font-size: 36px;
    }
    .course__module-description {
      font-size: 16px;
      line-height: 24px;
    }
    .course__progress-title {
      font-size: 24px;
    }
    .course__module-title-list {
      font-size: 24px;
    }
    .course__progress-quote {
      font-size: 18px;
    }
  }
}
