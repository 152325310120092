#statistic * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#statistic {
    --nav-height: 63px;
    --margin-value: 20px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    width: 80%;
    min-height: calc(100vh - var(--nav-height) - (2 * var(--margin-value)));
    margin: var(--margin-value) auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
}

#statistic .statistic__top-bar {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 90px;
    gap: 20px;
}

#statistic .statistic__top-bar .success-bar > .success-bar__heading {
    font-size: 1rem;
    font-weight: 400;
}

#statistic .statistic__chart {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;
}

#statistic .statistic__right-bar {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}

#statistic .statistic__bottom-bar {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 230px;
    gap: 20px;
}

@media screen and (max-width: 1600px) {
    #statistic {
        width: 90%;
    }
}

@media screen and (max-width: 1500px) {
    #statistic {
        width: 95%;
        grid-template-rows: auto 2fr 1fr 1fr;
    }

    #statistic .statistic__chart {
        grid-column-start: 1;
        grid-column-end: 7;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    #statistic .statistic__bottom-bar {
        height: auto;
    }

    #statistic .statistic__right-bar {
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 7;
    }

    #statistic .statistic__bottom-bar {
        grid-row-start: 3;
        grid-row-end: 4;
    }
}

@media screen and (max-width: 800px) {
    #statistic {
        grid-template-rows: auto 450px 1fr 1fr;
    }
}

@media screen and (max-width: 660px) {
    #statistic .statistic__bottom-bar {
        flex-flow: row wrap;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    #statistic {
        grid-template-rows: auto 300px 1fr 1fr;
    }
}
