.thanks-page__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 15% 0 0 0;

  .thanks-page__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .thanks-page__text {
      text-align: center;
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
    }
  }
}

@media screen and (max-width: 1280px) {

  .thanks-page__container {
    gap: 16px;
    padding: 15% 5% 0 5%;

    .thanks-page__text {
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 60px;
    }
  }
}

@media screen and (max-width: 1050px) {

  .thanks-page__container {
    flex-direction: column;
    gap: 32px;
    padding: 15% 0 0 0;

    .thanks-page__text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      .thanks-page__text {
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
      }
    }
  }
}

@media screen and (max-width: 480px) {

  .thanks-page__container {
    overflow: hidden;

    .thanks-page__text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      .thanks-page__text {
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
      }
    }
  }
}



@media screen and (max-width: 380px) {

  .thanks-page__container {
    flex-direction: column;
    gap: 16px;
    padding: 5%;
    overflow: hidden;

    .thanks-page__text-container {
      .thanks-page__text {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px;
      }
    }
  }
}