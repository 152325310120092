.modal_window_style {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background-color: whitesmoke;
    padding: 10px 20px 40px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.dialogCloseButtonStyles {
    margin-bottom: 15px;
    padding: 3px 8px;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    width: 30px;
    height: 30px;
    font-weight: bold;
    align-self: flex-end;
}

.modal_window_bg_layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100vh;
    background: #000;
    opacity: 0.5;
}

.modal_window_style .modal_window_style--close {
    position: relative;
    left: calc(99% - 20px);
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.modal_window_style input {
    font-size: 20px;
    border-radius: 5px;
    background-color: white;
    margin-top: 20px;
}

.modal_window_style div {
    font-size: 20px;
}

.modal_window_style div input {
    height: 20px;
    width: 20px;
}

.modal_window_style button {
    margin-top: 20px;
}

.modal_window_style textarea {
    margin-top: 20px;
    max-width: 100%;
    min-height: 60px;
    border-radius: 5px;
}

.modal_window_style > .ta-auto__words {
    max-height: 200px !important;
    min-height: 100px !important;
}

.modal-dialog__info-words > div {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 2;
}

.modal-dialog__info-words .modal-dialog__info-words_header {
    text-decoration: underline;
    font-weight: bold;
}

.modal-dialog__info-words .modal-dialog__info-words_side-first {
    border-radius: 4px;
    margin-right: 4px;
    border: 1px solid #e03366;
    padding: 2px;
}

.modal-dialog__info-words .modal-dialog__info-words_side-second {
    border-radius: 4px;
    border: 1px solid #2aa9e0;
    padding: 2px;
}
.dialog-cost {
    display: flex;
    justify-content: center;
}
.dialog-subscribe-cons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    justify-content: space-between;
    text-align: center;
    padding: 0;
}
.dialog-subscribe-cons > li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    background-color: #1991ff;
    padding: 3px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgb(0 0 0);
}

.dialog-subscribe-cons > li:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
    -webkit-transform: scale(1.025);
    transform: scale(1.025);
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .modal_window_style button {
        height: 40px;
    }
}
