.simple-checkbox .checkbox {
    cursor: pointer;
}
.simple-checkbox .checkbox input {
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;
    cursor: inherit;
}

.simple-checkbox .checkbox .checkbox__text {
    position: relative;
    padding: 0 0 0 50px;
    font-family: inherit;
    font-size: 20px;
    cursor: inherit;
    line-height: 20px;
}

.simple-checkbox .checkbox .checkbox__text:before {
    content: '';
    position: absolute;
    left: 0;
    width: 40px;
    height: 20px;
    border-radius: 13px;
    background: #cdd1da;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
    transition: 200ms ease;
}

.simple-checkbox .checkbox .checkbox__text:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: 200ms ease;
}

.simple-checkbox input:checked + .checkbox__text:before {
    background: #3ed468;
}

.simple-checkbox input:checked + .checkbox__text:after {
    left: 22px;
}
.simple-checkbox .checkbox label {
    color: black;
}

.simple-checkbox .checkbox__text:hover {
    color: gray;
    cursor: pointer;
}
