#doneword-dialog {
    max-height: 70vh;
    min-height: 70vh;
}

#doneword-dialog #doneword-dialog_content {
    max-height: 80%;
    min-height: 80%;
    height: 70vh;
    overflow-y: auto;
    color: black;
    margin-top: 24px;
}

#doneword-dialog #doneword-dialog_content li {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1100px) {
    #doneword-dialog {
        width: 600px;
    }
}

@media screen and (max-width: 640px) {
    #doneword-dialog {
        width: 90%;
    }
}

@media screen and (max-width: 480px) {
    #doneword-dialog {
        width: 90%;
    }
}
