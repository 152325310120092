@import '../variables.scss';

#rules {
  margin-top: 2em;
}

#rules .rules_rules-area {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  list-style: none;
}

#rules .rule-test label {
  font-size: 20px;
}

#rules .rules_rules-area a {
  outline: none;
  text-decoration: none;
  color: black;
}

#rules .rule_rules-content {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 20px;
}

#rules .rule_rules-content div {
  width: 60vw;
}

#rules .rule-card_image {
  display: flex;
  justify-content: center;
}

#rules .rules_rules-area_rules-card {
  cursor: pointer;
  box-shadow: 0 2px 5px rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: -webkit-transform 0.1s ease-in;
  transition: transform 0.1s ease-in;
}

#rules .rules_rules-area_rules-card:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  -webkit-transform: scale(1.025);
  transform: scale(1.025);
}

#rules .rules-card_type {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #e03366;
  text-align: center;
}

#rules .rules-card_img {
  height: 320px;
  width: 300px;
}

#rules .rules_admin_button {
  margin-top: 20px;
  margin-bottom: 20px;
}

#rules .rules_admin_button > span {
  margin-left: 5px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

#rules .rules-card_name {
  font-size: 25px;
  text-align: center;
  background-color: #2aa9e0;
  margin: 0;
  padding: 7px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#rules .rules-header {
  font-size: 2em;
  text-align: center;
}

#rules .rules-header h2 {
  font-style: italic;
  margin-top: -50px;
}

#rules .number-block {
  text-align: left;
}

#rules .number-block > h3 {
  margin-top: 20px;
  padding-left: 10px;
  width: 37px;
  background-color: #a583ff;
  border-radius: 50%;
}

#rules .task-example {
  text-align: left;
}

#rules .number-block {
  font-style: italic;
  font-weight: bold;
}

#rules .example {
  text-align: left;
  display: grid;
  margin-top: 5px;
  grid-gap: 5px;
}

#rules .example div {
  padding: 8px;
  font-style: italic;
  font-weight: bold;
}

#rules .example div:nth-of-type(1) {
  background-color: #cfe2f3;
}

#rules .example div:nth-of-type(2) {
  background-color: #d9d2e9;
}

#rules .example-dialog {
  text-align: left;
  display: grid;
  margin-top: 5px;
  grid-gap: 5px;
}

#rules .example-dialog li {
  padding: 8px;
  font-style: italic;
  font-weight: bold;
}

#rules .example-dialog li {
  background-color: #cfe2f3;
}

#rules .fact-rule {
  text-align: left;
  font-weight: bold;
}

#rules .very-important-words {
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  font-style: italic;
}

#rules .very-important-words u {
  display: inline-block;
}

#rules ul {
  list-style: none;
  padding-inline-start: 0;
}

#rules .table-two li {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
  grid-gap: 5px;
}

#rules .table-three {
  margin-bottom: 30px;
}

#rules .table-three li {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 5px;
  grid-gap: 5px;
}

#rules .table-two li span {
  background-color: #cfe2f3;
  padding: 8px;
  font-style: italic;
  font-weight: bold;
}

#rules .table-three li span {
  background-color: #cfe2f3;
  padding: 8px;
  font-style: italic;
  font-weight: bold;
}

#rules .table-three li span:nth-of-type(2) {
  background-color: #d9d2e9;
}

#rules .table-four li {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  margin-top: 5px;
  grid-gap: 5px;
}

#rules .table-four li span {
  background-color: #cfe2f3;
  font-style: italic;
  padding: 8px;
}

#rules .table-four li span:nth-of-type(1) {
  font-weight: bold;
  background-color: #d9d2e9;
}

#rules .table-four li:nth-of-type(1) span {
  font-weight: bold;
  background-color: #d9d2e9;
}

#rules .table-two-vs li {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
  grid-gap: 5px;
}

#rules .table-two-vs li span {
  background-color: #cfe2f3;
  padding: 8px;
  font-style: italic;
  font-weight: bold;
}

#rules .table-two-vs li span:nth-of-type(2) {
  background-color: #d9d2e9;
}

#rules .table-two-vs-not-paint li {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
  grid-gap: 5px;
}

#rules .table-two-vs-not-paint li span {
  padding: 8px;
  font-style: italic;
  font-weight: bold;
}

#rules .table-uniq-one {
  text-align: center;
  display: grid;
  font-style: italic;
  font-weight: bold;
  grid-template-columns: 2fr 1fr 4fr;
  grid-template-areas:
        'A B1 C1'
        'A B1 C1'
        'A B1 C2'
        'A B2 C2'
        'A B2 C3'
        'A B2 C3';
  margin-top: 5px;
  grid-gap: 5px;
}

#rules .table-uniq-two {
  text-align: center;
  display: grid;
  font-style: italic;
  font-weight: bold;
  grid-template-columns: 2fr 1fr 1fr 4fr;
  grid-template-areas:
        'A B1 C D1'
        'A B1 C D1'
        'A B1 C D2'
        'A B2 C D2'
        'A B2 C D3'
        'A B2 C D3';
  margin-top: 5px;
  grid-gap: 5px;
}

#rules .table-uniq-one li:nth-of-type(1) {
  background-color: #cfe2f3;
  grid-area: A;
}

#rules .table-uniq-one li:nth-of-type(2) {
  background-color: #d9d2e9;
  grid-area: B1;
}

#rules .table-uniq-one li:nth-of-type(3) {
  background-color: #d9d2e9;
  grid-area: B2;
}

#rules .table-uniq-one li:nth-of-type(4) {
  background-color: #cfe2f3;
  grid-area: C1;
}

#rules .table-uniq-one li:nth-of-type(5) {
  background-color: #cfe2f3;
  grid-area: C2;
}

#rules .table-uniq-one li:nth-of-type(6) {
  background-color: #cfe2f3;
  grid-area: C3;
}

#rules .table-uniq-two li:nth-of-type(1) {
  background-color: #cfe2f3;
  grid-area: A;
}

#rules .table-uniq-two li:nth-of-type(2) {
  background-color: #d9d2e9;
  grid-area: B1;
}

#rules .table-uniq-two li:nth-of-type(3) {
  background-color: #d9d2e9;
  grid-area: B2;
}

#rules .table-uniq-two li:nth-of-type(4) {
  background-color: #cfe2f3;
  grid-area: C;
}

#rules .table-uniq-two li:nth-of-type(5) {
  background-color: #d9d2e9;
  grid-area: D1;
}

#rules .table-uniq-two li:nth-of-type(6) {
  background-color: #d9d2e9;
  grid-area: D2;
}

#rules .table-uniq-two li:nth-of-type(7) {
  background-color: #d9d2e9;
  grid-area: D3;
}

#rules .rule-test {
  margin-top: 20px;
}

#rules .next-div-block > div {
  display: block;
}

#rules .rule-card {
  @include card-container;
  display: flex;
  flex-flow: column nowrap;
  cursor: pointer;
  min-height: 390px;
  min-width: 270px;
  transition: transform 0.1s ease-in;
}

#rules .rule-card div {
  display: flex;
  flex-flow: column nowrap;
}

#rules .rule-card-status-badge {
  position: relative;
}

#rules .rule-card-status-badge > svg {
  position: absolute;
  left: 88%;
  top: -25px;
  z-index: 98;
}

#rules .rule-card:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  -webkit-transform: scale(1.025);
  transform: scale(1.025);
}

#rules .rule-card .rule-card__type {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #bdb7b9;
  font-size: 1.4rem;
  text-align: center;
}

#rules .rule-card .rule-card__img {
  height: 320px;
  width: 270px;
}

#rules .rule-card .rule-card__name {
  font-size: 1.6rem;
  text-align: center;
  background-color: #2aa9e0;
  margin: 0;
  padding: 8px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 270px;
}

.rule-card-lp-container {
  margin-left: 48px;

  .rule-card-lp {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    .rule-card__name-lp {
      font-size: 24px;
    }

   .rule-card__img-lp {
      height: 320px;
      width: 270px;
     @include card-container
    }
  }
}


@media screen and (max-width: 800px) {
  #rules .rule_rules-content .rules-header {
    font-size: 1em;
    margin: 50px auto;
  }

  #rules .rule_rules-content .rules-header h2 {
    margin-top: 10px;
  }

  #rules .rule_rules-content div {
    width: 93vw;
  }
}
