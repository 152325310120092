#navigation-bar {
  width: 260px;
  min-height: 100vh;
  background-color: #3C5482;

  .menu-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }


  .navigation-bar__site-name {
    margin-top: 10px;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      color: white;
      font-size: 1.8em;
    }

    img {
      height: 25px;
      margin-right: 5px;
    }
  }

  .navigation-bar__addition-info {
    margin-top: 10px;
    display: flex;

    ul {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 15px
    }

    li {
      margin-left: -15px;
      list-style: none;
    }

  }

  .navigation-bar__menu {
    height: 100%;

  }

  .ant-menu {
    background-color: #3C5482;
  }

  .ant-menu-item {
    color: white;
    margin-top: 20px;
  }

  .ant-menu-item-selected {
    color: #0a0a0a;
  }

  .navigation-bar__profile-container {
    position: relative;
    display: flex;
    padding: 10px;
    align-items: center;
    column-gap: 10px;
    width: 80%;
    margin: 10px auto 0;
    border-radius: 10px;
    cursor: pointer;
    right: 8px;

    span {
      color: white;
    }
  }

}

@media screen and (max-height: 768px) {
  #navigation-bar {
    .navigation-bar__addition-info {
      display: none;
    }

    .ant-menu {
      font-size: 14px;
    }

    .ant-menu-item {
      margin-top: 0;
    }

    .menu-icon {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-height: 864px) {
  #navigation-bar {
    .navigation-bar__addition-info {
      display: none;
    }

    .ant-menu-item {
      margin-top: 7px;
    }
  }
}