.loading_cycle .loader {
    position: relative;
    padding-top: 20px;
    width: 40px;
    margin: 40px auto;
}
.loading_cycle .loader .circle {
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 0;
    transform: rotate(225deg);
    animation-iteration-count: infinite;
    animation-name: orbit;
    animation-duration: 5.5s;
}
.loading_cycle .loader .circle:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background: #851988;
}
.loading_cycle .loader .circle:nth-child(2) {
    animation-delay: 240ms;
}
.loading_cycle .loader .circle:nth-child(3) {
    animation-delay: 480ms;
}
.loading_cycle .loader .circle:nth-child(4) {
    animation-delay: 720ms;
}
.loading_cycle .loader .circle:nth-child(5) {
    animation-delay: 960ms;
}
@keyframes orbit {
    0% {
        transform: rotate(225deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }
    7% {
        transform: rotate(345deg);
        animation-timing-function: linear;
    }
    30% {
        transform: rotate(455deg);
        animation-timing-function: ease-in-out;
    }
    39% {
        transform: rotate(690deg);
        animation-timing-function: linear;
    }
    70% {
        transform: rotate(815deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }
    75% {
        transform: rotate(945deg);
        animation-timing-function: ease-out;
    }
    76% {
        transform: rotate(945deg);
        opacity: 0;
    }
    100% {
        transform: rotate(945deg);
        opacity: 0;
    }
}
