#fill-word .fill-word_cross-area {
    display: flex;
    justify-content: center;
}

#fill-word h1 {
    text-align: center;
}

#fill-word .fill-word__answers {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#fill-word > .fill-word__rus-words {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}

#fill-word > .fill-word__rus-words > span {
    padding: 5px 10px;
    font-size: 0.7em;
}

#fill-word .fill-word__content_grid {
    display: grid;
    justify-content: center;
}

#fill-word .fill-word__answers ul {
    list-style: none;
}

#fill-word .fill-word_cross-area canvas {
    cursor: pointer;
    border-right: 1px solid;
    border-bottom: 1px solid;
}

@media screen and (min-width: 1680px) {
    #fill-word .fill-word__content_grid {
        grid-template-columns: 350px 1fr 350px;
    }

    #fill-word #wordlist-combine-selector {
        margin-right: 30px;
        grid-column: 3;
        height: 200px;
        grid-row: 1;
    }

    #fill-word .fill-word_cross-area {
        grid-column: 2;
        grid-row: 1;
    }

    #fill-word .fill-word__answers {
        margin-left: 20px;
        grid-column: 1;
        grid-row: 1;
        justify-content: flex-start;
    }

    #fill-word > .fill-word__rus-words > span {
        font-size: 1em;
    }
}
