@import '../variables.scss';

#banner {
  display: flex;
  justify-content: end;
  gap: 16px;
  width: 98%;
  margin-top: 30px;
    .banner__btn {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 4px 15px;
      border-radius: 10px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
}

@media screen and (max-width: 820px) {
  #banner {
    flex-direction: column-reverse;
    align-items: end;
  }
  }