@import '../variables.scss';

.dictionary_right_bar {
  .add-word-list-btn {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  .dictionary_left_bar-li-maxAmount {
    text-align: center;
    box-shadow: none;
    list-style: none;
    padding: 10px 80px;
    border-radius: 5px;
  }
}