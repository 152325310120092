#test select {
    font-size: 16px;
}

#test #eng {
    width: auto;
    color: white;
    background-color: #4582FF;
    font-size: 40px;
    text-align: center;
    padding: 20px 0px;
    margin: 40px auto;
}

#test #eng:first-letter {
    text-transform: uppercase;
}

#test #flex {
    justify-content: center;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
    width: 80%;
}

#test .test-area__header {
    margin-top: 10px;
    font-size: 30px;
    display: flex;
    justify-content: space-around;
}

#test .rus:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
}

#test .engWord,
#test .rusWord {
    font-size: 20px;
    padding: 10px;
}

#test .wrongWord {
    text-align: center;
}

#test .wrongWord b {
    cursor: pointer;
}


#test .word_item {
    margin-top: 10px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
}

#test .word_list_eng {
    width: 500px;
    text-align: center;
    padding: 3px 10px;
    background-color: #e03366;
    word-wrap: break-word;
    border-radius: 10px;
}

#test .word_list_rus {
    width: 500px;
    padding: 3px 10px;
    text-align: center;
    background-color: #2aa9e0;
    word-wrap: break-word;
    border-radius: 10px;
}

#test .word_list_hr {
    display: none;
}

#test .highlightRight {
    background-color: #70e086 !important;
}

#test .highlightFalse {
    background-color: #e01f20 !important;
}

#test .testAreaAnswer {
    cursor: pointer;
    color: #fff;
    background-color: #1677ff;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    font-size: 20px;
    text-align: center;
    padding: 6.428571428571429px 15px;
    border-radius: 8px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#test .testAreaAnswer:hover {
    color: #fff;
    background-color: #4096ff;
    box-shadow: none;
    -webkit-transform: scale(1.025);
}

@media all and (max-width: 1420px) {
    #test #flex {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media all and (max-width: 840px) {
    #test #flex {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media all and (max-width: 1000px) {
    @media all and (max-width: 1000px) {
        #test .word_item {
            grid-template-columns: repeat(1, 1fr);
            border-radius: 10px;
        }
    }
    @media all and (max-width: 500px) {
        #test #wrongWords_list {
            width: 100vw;
            margin-top: 10px;
            color: black;
            font-size: 1.3em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        #test .word_list_eng,
        #test .word_list_rus {
            width: 100vw;
        }

        #test .word_list_hr {
            display: block;
            width: 100%;
        }
    }
}
