.chart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: white;
}

.chart__wrapper {
    padding: 24px;
}
