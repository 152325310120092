@import '../variables.scss';


.mobile-layout__sidebar {
  display: none;
}

.mobile-layout__sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.navbar__burger {
  cursor: pointer;
  margin-top: 3px;
}

.mobile-layout__sidebar .navbar__icon-back {
  width: 40px;
  height: 40px;
}

#navbar {
  .navbar {
    overflow: hidden;
    height: 63px;
    background-color: $menuColor;
  }

  .mobile-layout__sidebar .navbar__link-back {
    line-height: 63px;
    padding: 0 calc((63px - 32px) / 2);
  }

  .mobile-layout__sidebar {
    -webkit-box-shadow: -5px -5px 5px -5px rgba(34, 60, 80, 0.6);
    -moz-box-shadow: -5px -5px 5px -5px rgba(34, 60, 80, 0.6);
    box-shadow: -5px -5px 5px -5px rgba(34, 60, 80, 0.6);
  }
}

@media (max-width: 480px) {
  .main-layout__sidebar {
    min-width: 100vw;
    display: none;
  }

  .mobile-layout__sidebar {
    display: block;
  }
}