@import '../variables.scss';

#courses {
  width: 98%;
  margin: 48px 0 0 32px;
}

@media screen and (max-width: 1280px) {
  #courses {
    width: 90%;
    flex-direction: column;
    margin: 32px;
  }
}

@media screen and (max-width: 780px) {
  #courses {
    margin: 16px;
  }
}
